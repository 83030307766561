<template>
  <el-table :data="logActions">
    <el-table-column label="Usuário" align="center" prop="User" />
    <el-table-column label="Pedido" align="center" prop="OrderNumber" />
    <el-table-column label="Loja" align="center" prop="EcommerceName" />
    <el-table-column label="Data" align="center" prop="Date" :formatter="dateFormatter" />
    <el-table-column label="Campo alterado" align="left" min-width="150">
      <template slot-scope="props">
        <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
          {{ item.field || '-' }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Motivo" align="center" prop="Reason" min-width="200" />
  </el-table>
</template>
<script>
import moment from "moment";
export default {
  props: {
    logActions: {
      type: Array,
      default: function () {
        return [{
          id: "",
          orderNumber: "",
          message: "",
          ecommerceName: ""
        }];
      },
    },
  },
  methods: {
    dateFormatter(row, col, value, index) {
      return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
    },
    formatDescription(description) {
      let descriptionArray = []
      let obj = JSON.parse(description)

      for (const key in obj) {

        descriptionArray.push({
          field: key
        })
      }
      return descriptionArray
    },
  }
};
</script>
<style scoped>
.my-bg-header {
  background-color: #27293d !important;
}
</style>
