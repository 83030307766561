<template>
  <div>
    <el-table :data="paymentMethods" ref="multipleTable">
      <el-table-column label="Id" min-width="50" align="center" prop="id"></el-table-column>
      <el-table-column label="Nome" min-width="100" prop="name">
      </el-table-column>
      <el-table-column label="Referência no E-commerce" align="center" prop="ecommerceReference">
      </el-table-column>
      <el-table-column label="Referência no ERP" align="center" prop="erpReference">
      </el-table-column>
      <el-table-column label="Condição de pagamento" min-width="50" align="center" prop="paymentCondition">
      </el-table-column>
      <el-table-column :min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.id)"
            v-if="getUserPermission('31_c')">
            <i class="material-icons">mode_edit_outline</i>
          </base-button>
          <base-button class="like !important btn-link" type="danger" size="sm" icon @click="remove(props.row.id)"
            v-if="getUserPermission('31_c')">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <router-view></router-view>
  </div>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import swal from "sweetalert2";
import { mapActions } from 'vuex';

export default {
  components: {
    ListBullet
  },
  props: {
    paymentMethods: {
      type: Array,
      default: function () {
        return [{
          id: "",
          name: "",
          skus: [],
          idEcommerce: 0,
          type: "",
          status: ""
        }];
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
      dialogVisible: false,
      skus: []
    };
  },
  methods: {
    ...mapActions('paymentMethod', ['getPaymentMethods', 'removePaymentMethod']),
    edit(id) {
      this.$router.push({ name: "PaymentMethodEdit", params: { id: id } });
    },
    remove(id) {
      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.delete(id)
        }
      });
    },
    async delete(id) {
      await this
        .removePaymentMethod(id)
        .then(async () => {
          this.$showSuccess("O cadastro foi excluido com sucesso")
          await this.getPaymentMethods();
        })
        .catch(error => { this.$showError(error) });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style scoped>
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: inline-block;
  width: 60px;
  height: auto;
  background-color: transparent !important;
}

.my-bg-header {
  background-color: #27293d !important;
}

.el-avatar img {
  height: auto !important;
}
</style>
