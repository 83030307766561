<template>
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <card class="stacked-form">
        <form>
          <div class="row">
            <div class="col-3">
              <base-input readonly label="Operação Fiscal" v-model="taxOperation"></base-input>
            </div>
            <div class="col-3">
              <base-input readonly label="Nº do Pedido" v-model="fulfillmentCurrent.Number"></base-input>
            </div>
            <div class="col-2">
              <base-input readonly label="Data de criação">
                <el-date-picker readonly format="dd/MM/yyyy" v-model="fulfillmentCurrent.PurchaseDate">
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-2">
              <label>Status</label>
              <div :class="getStatusClass(fulfillmentCurrent.Status.BahnReference)">
                {{ fulfillmentCurrent.Status.AliasName }}
              </div>
            </div>
            <div class="col-2">
              <base-input label="Data de Integração">
                <el-date-picker readonly format="dd/MM/yyyy" v-model="fulfillmentCurrent.IntegratedDate">
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-3">
              <base-input readonly label="Nº Pedido Marketplace"
                v-model="fulfillmentCurrent.ExternalNumberId"></base-input>
            </div>
            <div class="col-3">
              <base-input readonly label="Nº da Nota" v-model="fulfillmentCurrent.NFNumber"></base-input>
            </div>
            <div class="col-2">
              <base-input readonly label="Série" v-model="fulfillmentCurrent.NFSerie"></base-input>
            </div>
            <div class="col-4">
              <base-input readonly label="Chave de Acesso" v-model="fulfillmentCurrent.NFKey"></base-input>
            </div>
            <div class="col-3">
              <base-input readonly label="Id Marketplace" v-model="fulfillmentCurrent.MarketplaceId"></base-input>
            </div>
            <div class="col-3">
              <base-input readonly label="Marketplace - Canal de Venda"
                v-model="fulfillmentCurrent.MarketplaceName"></base-input>
            </div>
            <div class="col-2">
              <base-input readonly label="Nº no ERP" v-model="fulfillmentCurrent.DocEntry"></base-input>
            </div>
            <div class="col-2">
              <base-input readonly label="Filiar ERP" v-model="fulfillmentCurrent.BplID"></base-input>
            </div>
            <div class="col-2">
              <base-input readonly label="Cód. Estoque no ERP" v-model="fulfillmentCurrent.WarehouseCode"></base-input>
            </div>
            <div class="col-3">
              <label>Valor de Frete ou Despesas</label>
              <h4 class="strong">{{ fulfillmentCurrent.Shipping.Price | toCurrency }}</h4>
            </div>
            <div class="col-2">
              <label>Descontos</label>
              <h4 class="strong">{{ sumDiscount | toCurrency }}</h4>
            </div>
            <div class="col-2">
              <label>Total da Nota</label>
              <h4 class="strong">{{ sumPaymentValues | toCurrency }}</h4>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import constants from '@/util/constants';
import { mapState } from 'vuex';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          operation: "",
          orderNumber: "",
          importedDate: null,
          store: "",
          status: ""
        }
      }
    }
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
    taxOperation() {
      switch (this.fulfillmentCurrent.TaxOperation) {
        case 1:
          return "Remessa para envio";
        case 2:
          return "Retorno para Transferência";
        case 3:
          return "Remessa para Transferência";
        case 4:
          return "Nota de Venda";
        case 5:
          return "Retorno simbólico de venda";
        case 6:
          return "Devolução de venda";
        default:
          return "Não informado"
      }
    },
    sumPaymentValues() {
      let total = 0;

      if (!this.fulfillmentCurrent.OrderPayments) return total;

      this.fulfillmentCurrent.OrderPayments.forEach((payment) => {
        total += payment.Total;
      });

      return total;
    },
    sumDiscount() {
      let discount = 0;

      if (!this.fulfillmentCurrent.OrderPayments) return discount;

      this.fulfillmentCurrent.OrderPayments.forEach((payment) => {
        discount += payment.Discount;
      });

      return discount;
    },
  },
  methods: {
    getStatusClass(status) {
      if (status == null)
        return "status statusDefault";

      let statusParams = {
        error: "statusError",
        integrated: "statusIntegrated",
        scheduled: "statusScheduled",
        processing: "statusProcessing",
        started: "statusProcessing",
        canceled: "statusCanceled",
        deleted: "statusCanceled",
        complete: "statusComplete",
        imported: "statusImported",
        delivered: "statusDelivered",
        dispatched: "statusDispatched",
        holded: "statusHolded",
        presales: "statusIntegrated",
        faturado: "statusFaturado",
        waitingTransfer: "statusScheduled",
        waitingReturn: "statusScheduled",
        "em-remessa": "statusIntegrated"
      };

      return `status ${statusParams[status.toLowerCase()]}` || "status statusDefault";
    },
    getStatusDescription(status) {
      let sts = constants.FulfillmentStatusEnum.find(a => a.key === status);
      return sts ? sts.description : "";
    }
  }
}
</script>