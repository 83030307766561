<template>
  <div class="row" style="float: left">
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker
          v-model="filters.startDate"
          type="date"
          placeholder="De"
          format="dd/MM/yyyy"
        >
        </el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker
          v-model="filters.endDate"
          type="date"
          placeholder="Até"
          format="dd/MM/yyyy"
        >
        </el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome do arquivo" v-model="filters.name"></el-input>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="filters.status"
          placeholder="Status"
        >
          <el-option
            class="select-primary"
            v-for="item in statuses"
            :key="item.name"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      filters: {
        name: null,
        startDate: null,
        endDate: null,
        status: null,
      },
      statuses: [
        {
          name: "Sucesso",
          value: "success",
        },
        {
          name: "Falha Parcial",
          value: "partialsucess",
        },
        {
          name: "Erro",
          value: "error",
        },
      ],
    };
  },
  methods: {
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        name: null,
        startDate: null,
        endDate: null,
        status: null,
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
  },
};
</script>