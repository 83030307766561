<template>
  <div>
    <button class="custom-btn-filter mt-3" @click="expandAll">{{ buttonName }}</button>

    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <h4><i class="header-icon el-icon-info"></i> Dados Gerais </h4>
        </template>
        <FullfillmentFormGeneral />
      </el-collapse-item>
      <el-collapse-item name="2"  style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-user"></i> Cliente </h4>
        </template>
        <FullfillmentFormClient />
      </el-collapse-item>
      <el-collapse-item name="3" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-money"></i> Pagamento </h4>
        </template>
        <FulfillmentFormPayment />
      </el-collapse-item>
      <el-collapse-item name="4" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-truck"></i> Entrega </h4>
        </template>
        <FulfillmentFormDelivery />
      </el-collapse-item>
      <el-collapse-item name="5"  style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-takeaway-box"></i> Produto(s) </h4>
        </template>
        <FullfillmentFormProducts />
      </el-collapse-item>
      <el-collapse-item name="6"  style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-tickets"></i> Logs </h4>
        </template>
        <FulfillmentFormLogs />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import FullfillmentFormGeneral from './FullfillmentFormGeneral.vue';
import FullfillmentFormClient from './FullfillmentFormClient.vue';
import FullfillmentFormProducts from './FullfillmentFormProducts.vue';
import FulfillmentFormLogs from './FulfillmentFormLogs.vue';
import FulfillmentFormPayment from './FulfillmentFormPayment.vue';
import FulfillmentFormDelivery from './FulfillmentFormDelivery.vue';

export default {
  components: {
    FullfillmentFormGeneral,
    FullfillmentFormClient,
    FullfillmentFormProducts,
    FulfillmentFormLogs,
    FulfillmentFormPayment,
    FulfillmentFormDelivery
  },
  data() {
    return {
      activeNames: ["1","2","3","4","5"],
      test: ""
    }
  },
  computed : {
    buttonName(){
      return this.activeNames.length > 0 ? "Recolher Todos" : "Expandir Todos";
    }
  },
  methods: {
    expandAll(){
      this.activeNames.length > 0 ? this.activeNames = [] : this.activeNames = ["1","2","3","4"];
    }
  },
  mounted() {
    let section = this.$route.query.section;
    if(section)
      this.activeNames.push(section);
  },
}
</script>
<style>
.el-collapse-item__wrap {
  background-color: #27293d;
}

.el-collapse-item__header {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header:hover {
  background-color: #1aac96;
  color: white;
  transition: background-color 0.5s ease;
}

.el-collapse {
  border-color: rgba(225, 225, 225, 0.187);
}

.el-icon-arrow-right:before {
  color: white;
}
</style>