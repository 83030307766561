<template>
    <div class="d-flex justify-content-center">
        <el-tooltip content="Documentos de Transferência" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.InvoiceTransfer">
            <el-dropdown trigger="click">
                <span class="">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                        role="img" class="iconify iconify--mdi" width="20" height="20"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                        <path :fill="getStatus('DocumentTransfers')"
                            d="M10 10L9 9h6l-1 1ZM3.25 8L4 6.5h16l.75 1.5Zm2-2.5L6 4h12l.75 1.5ZM3 20L2 9h5.5l2 2h5l2-2H22l-1 11Zm7.75-1.5q.25 0 .438-.137q.187-.138.262-.363h1.1q.075.225.262.363q.188.137.438.137q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525Q13.55 17 13.25 17q-.25 0-.438.137q-.187.138-.262.363h-1.1q-.025-.075-.062-.137q-.038-.063-.088-.113l.575-1.025q.05 0 .063.013q.012.012.062.012q.25 0 .438-.137q.187-.138.262-.363h1.35q.075.225.262.363q.188.137.438.137q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525q-.225-.225-.525-.225q-.25 0-.438.137q-.187.138-.262.363H12.7q-.05-.15-.15-.25l.575-1.025q.05 0 .063.013q.012.012.062.012q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525q-.225-.225-.525-.225q-.25 0-.438.137q-.187.138-.262.363h-1.1q-.075-.225-.262-.363Q11 12.5 10.75 12.5q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225q.25 0 .438-.137q.187-.138.262-.363h1.1q.025.075.063.137q.037.063.087.113l-.575 1.025q-.05 0-.063-.013q-.012-.012-.062-.012q-.25 0-.438.137q-.187.138-.262.363H9.95q-.075-.225-.262-.363q-.188-.137-.438-.137q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225q.25 0 .438-.137q.187-.138.262-.363h1.35q.05.15.15.25l-.575 1.025q-.05 0-.063-.013Q10.8 17 10.75 17q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225Z">
                        </path>
                    </svg>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="clearfix">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true" role="img" class="iconify iconify--mdi" style="margin:3" width="20"
                            height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" @click="showModal('11')">
                            <path :fill="getStatus('DocEntryNfTransfer')"
                                d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8Z">
                            </path>
                        </svg>
                        <span @click="showModal('11')"> Nota Fiscal de Saída</span>
                    </el-dropdown-item>

                    <el-dropdown-item class="clearfix">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true" role="img" class="iconify iconify--mdi" style="margin:3" width="20"
                            height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" @click="showModal('12')">
                            <path :fill="getStatus('DocEntryPurchaseDeliveryTransfer')"
                                d="M10 10L9 9h6l-1 1ZM3.25 8L4 6.5h16l.75 1.5Zm2-2.5L6 4h12l.75 1.5ZM3 20L2 9h5.5l2 2h5l2-2H22l-1 11Zm7.75-1.5q.25 0 .438-.137q.187-.138.262-.363h1.1q.075.225.262.363q.188.137.438.137q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525Q13.55 17 13.25 17q-.25 0-.438.137q-.187.138-.262.363h-1.1q-.025-.075-.062-.137q-.038-.063-.088-.113l.575-1.025q.05 0 .063.013q.012.012.062.012q.25 0 .438-.137q.187-.138.262-.363h1.35q.075.225.262.363q.188.137.438.137q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525q-.225-.225-.525-.225q-.25 0-.438.137q-.187.138-.262.363H12.7q-.05-.15-.15-.25l.575-1.025q.05 0 .063.013q.012.012.062.012q.3 0 .525-.225q.225-.225.225-.525q0-.3-.225-.525q-.225-.225-.525-.225q-.25 0-.438.137q-.187.138-.262.363h-1.1q-.075-.225-.262-.363Q11 12.5 10.75 12.5q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225q.25 0 .438-.137q.187-.138.262-.363h1.1q.025.075.063.137q.037.063.087.113l-.575 1.025q-.05 0-.063-.013q-.012-.012-.062-.012q-.25 0-.438.137q-.187.138-.262.363H9.95q-.075-.225-.262-.363q-.188-.137-.438-.137q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225q.25 0 .438-.137q.187-.138.262-.363h1.35q.05.15.15.25l-.575 1.025q-.05 0-.063-.013Q10.8 17 10.75 17q-.3 0-.525.225q-.225.225-.225.525q0 .3.225.525q.225.225.525.225Z">
                            </path>
                        </svg>
                        <span @click="showModal('12')"> Recebimento de Mercadoria</span>
                    </el-dropdown-item>

                    <el-dropdown-item class="clearfix">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true" role="img" class="iconify iconify--mdi" style="margin:3" width="20"
                            height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" @click="showModal('13')">
                            <path :fill="getStatus('DocEntryPurchaseInvoiceTransfer')"
                                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2Z">
                            </path>
                        </svg>
                        <span @click="showModal('13')"> Nota Fiscal de Entrada</span>
                    </el-dropdown-item>

                </el-dropdown-menu>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Pedido de Venda" effect="light" :open-delay="300" placement="top" v-if="erpDocuments.Order">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi"  width="20px" height="20" preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24" @click="showModal('2')">
                 <path :fill="getStatus('DocEntry')"
                    d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Picking" effect="light" :open-delay="300" placement="top" v-if="erpDocuments.Picking">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('7')">
                    <path :fill="getStatus('DocEntryPicking')"
                        d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4h3M3 6v9h.76c.55-.61 1.35-1 2.24-1c.89 0 1.69.39 2.24 1H15V6H3Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Nota Fiscal" effect="light" :open-delay="300" placement="top" v-if="erpDocuments.Invoice">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('6')">
                    <path :fill="getStatus('DocEntryNf')"
                        d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Contas a Receber" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.IncomingPayment">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('5')">
                    <path :fill="getStatus('DocEntryCR')"
                        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Adiantamento de Cliente" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.DownPayment">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('8')" :disabled="erpDocuments.DownPayment === 0">
                    <path :fill="getStatus('DocEntryAdto')"
                        d="M11 8c0 2.21-1.79 4-4 4s-4-1.79-4-4s1.79-4 4-4s4 1.79 4 4m0 6.72V20H0v-2c0-2.21 3.13-4 7-4c1.5 0 2.87.27 4 .72M24 20H13V3h11v17m-8-8.5a2.5 2.5 0 0 1 5 0a2.5 2.5 0 0 1-5 0M22 7a2 2 0 0 1-2-2h-3c0 1.11-.89 2-2 2v9a2 2 0 0 1 2 2h3c0-1.1.9-2 2-2V7Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        documents: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "Number": null,
                "DocEntry": null,
                "DocEntryAdto": null,
                "DocEntryCR": null,
                "DocEntryNf": null,
                "DocEntryPicking": null,
                "DocEntryNfTransfer": null,
                "DocEntryPurchaseDeliveryTransfer": null,
                "DocEntryPurchaseInvoiceTransfer": null
            })
        },
        erpDocuments: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "IncomingPayment": false,
                "Invoice": false,
                "DownPayment": false,
                "Picking": false,
                "InvoiceTransfer": false,
                "PurchaseDeliveryNotes": false,
                "PurchaseInvoice": false
            })
        }
    },
    data() {
        return {
            contentDocumentTransfer: "Documentos de Transferência",
        }
    },
    computed: {
        ...mapState(['ecommerce', "realm"])
    },
    methods: {
        getStatusDocumentTransfers() {
            return (this.documents.DocEntryNfTransfer !== 0 &&
                this.documents.DocEntryPurchaseDeliveryTransfer !== 0 &&
                this.documents.DocEntryPurchaseInvoiceTransfer !== 0);
        },
        getStatus(prop) {
            switch (prop) {
                case 'DocEntry':
                    return this.documents.DocEntry !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryCR':
                    return this.documents.DocEntryCR !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryNf':
                    return this.documents.DocEntryNf !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPicking':
                    return this.documents.DocEntryPicking !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryAdto':
                    return this.documents.DocEntryAdto !== 0 ? "#00ADB7" : "#888888";
                case 'DocumentTransfers':
                    return this.getStatusDocumentTransfers() ? "#00ADB7" : "#888888";
                case 'DocEntryNfTransfer':
                    return this.documents.DocEntryNfTransfer !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPurchaseDeliveryTransfer':
                    return this.documents.DocEntryPurchaseDeliveryTransfer !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPurchaseInvoiceTransfer':
                    return this.documents.DocEntryPurchaseInvoiceTransfer !== 0 ? "#00ADB7" : "#888888";
            }
        },
        showModal(prop) {
            if (this.documents && this.documents.Number) {
                const number = this.documents.Number.toString();
                this.$router.push({
                    name: 'DocumentJson',
                    params: { number, type: prop },
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #F5F7FA !important;
    color: #1d253b !important;
}
</style>