<template>
  <el-table :data="productIntegrationLog">
    <el-table-column label="Produto" min-width="50" align="center" prop="ProductId"></el-table-column>
    <el-table-column label="Status" min-width="50" align="center" prop="StatusDescription"></el-table-column>
    <el-table-column label="Tipo de Evento" min-width="50" align="center" prop="TypeDescription"></el-table-column>
    <el-table-column label="Data" min-width="100" prop="ProcessedDate"></el-table-column>
    <el-table-column label="Mensagem" align="center" prop="Message">
    </el-table-column>
  </el-table>
</template>
<script>
import { mapGetters } from 'vuex';


export default {
  computed: {
    ...mapGetters("product",["productIntegrationLog"])
  },
};
</script>
<style scoped>
.my-bg-header {
  background-color: #27293d !important;
}
</style>
