103 lines (96 sloc)  2.82 KB
<template>
  <div class="example-simple">
    <h4 id="example-title" class="card-title">Importar arquivo com relação de SKUs</h4>
    <div class="upload">
      <ul>
        <li v-for="(file) in files" :key="file.id">
          <span>{{ file.name }}</span> -
          <span>{{ file.size | formatSize }}</span>
        </li>
      </ul>
      <div class="example-btn">
        <file-upload class="btn btn-primary" post-action="/upload/post" extensions="xlsx" accept=".xlsx"
          :multiple="false" :size="1024 * 1024 * 10" v-model="files" @input-filter="inputFilter" @input-file="inputFile"
          ref="upload">
          <i class="fa fa-plus"></i>
          Selecionar Arquivo
        </file-upload>
        <button type="button" class="btn btn-success"
          v-if="getUserPermission('15_c') && (!$refs.upload || !$refs.upload.active)"
          @click.prevent="$refs.upload.active = true">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Começar Importação
        </button>
        <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
          <i class="fa fa-stop" aria-hidden="true"></i>
          Parar importação
        </button>
        <a class="ml-2 btn btn-info" :href="templateImport" download>
          <i class="fa fa-arrow-down" aria-hidden="true"></i> Baixar Template
        </a>
      </div>
    </div>
    <div class="pt-5">
    </div>
  </div>
</template>
<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>

<script>
import FileUpload from 'vue-upload-component'
import swal from "sweetalert2";
export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      addFile: true,
      templateImport: require('@/assets/template-xls/Template-Importar-Mapeamento.xlsx')
    }
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files

        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: async function (newFile, oldFile) {
      var vm = this;
      if (newFile && !oldFile) {
        // add
        this.addFile = true;
      }
      if (newFile && oldFile) {
        // update
        let formData = new FormData();
        formData.append("file", this.$refs.upload.files[0].file);

        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        if (this.addFile) {
          this.addFile = false;
          await this.$bahngleis
            .post("ProductSync/import-plan?ecommerceName=" + this.$store.state.ecommerce.Name, formData, config)
            .then(async function (response) {
              if (response.status === 200) {
                swal({
                  title: "Sucesso",
                  text: "O arquivo foi importado corretamente",
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                vm.addFile = true;
                vm.$refs.upload.files[0].file.success = true;

              }
              else {
                swal({
                  title: "Atenção",
                  text: "Ocorreu um erro ao importar o arquivo. " + response.message,
                  type: "error",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
              }
            }).catch(err => {
              swal({
                title: "Atenção",
                html: `Ocorreu um erro ao importar o arquivo.<br> <b>Motivo: ${err.response.data}</b>`,
                type: "error",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false
              });
            });
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
}
</script>
