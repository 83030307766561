<template>
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <card class="stacked-form">
        <div
          class="row"
          v-for="(payment, index) in orderCurrent.OrderPayments"
          :key="index"
        >
          <div class="col-4 col-lg-3">
            <label>Método de Pagamento</label>
            <div v-if="existError(payment) && orderNotIntegrated">
              <el-select
                label="Método de pagamento"
                ref="paymentMethod"
                id="paymentMethod"
                @change="changePaymentMethod($event, payment.Id)"
                placeholder="Método de pagamento"
                v-model="payment.PaymentMethod"
                required
              >
                <el-option
                  class="select-primary"
                  v-for="(item, index) in paymentMethods"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
              <span style="font-size: 10px; display: flex">
                <p style="color: #f22435; padding-right: 5px">
                  Método de pagamento não encontrado
                </p>
                <a
                  href="javascript:void(0)"
                  @click="$router.push({ name: 'PaymentMethods' })"
                >
                  <i
                    class="tim-icons icon-simple-add"
                    style="padding: 5px; font-size: 5pt"
                  ></i>
                  <strong>Cadastrar</strong>
                </a>
              </span>
            </div>
            <p v-else>{{ payment.PaymentMethod }}</p>
          </div>
          <div align="center" class="col-3 col-lg-2">
            <label>Parcelas</label>
            <p>{{ payment.Installments }}</p>
          </div>
          <div class="col-4 col-lg-2">
            <label>Valor</label>
            <p>{{ payment.Total | toCurrency }}</p>
          </div>
          <div class="col-12 col-lg-2">
            <label>NSU</label>
            <p>{{ payment.NSU }}</p>
          </div>
          <div class="col-12 col-lg-2">
            <label>TID</label>
            <p>{{ payment.TID }}</p>
          </div>
          <el-divider></el-divider>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import constants from "@/util/constants";

export default {
  props: {
    paymentMethods: { type: Array },
  },
  computed: {
    ...mapState("orders", ["orderCurrent"]),
    address() {
      if (this.orderCurrent && this.orderCurrent.OrderAddress)
        return this.orderCurrent.OrderAddress.find(
          (a) => a.AddressType === constants.AddressType.Billing
        );

      return {
        Name: "",
        ZipCode: null,
        Street: null,
        Number: null,
        Complement: null,
        Neighborhood: null,
        Country: null,
        City: null,
      };
    },
    orderNotIntegrated() {
      return this.orderCurrent.Status.BahnReference === "error" ? true : false;
    },
  },
  methods: {
    ...mapMutations("orders", ["UPDATE_PROPERTY_ORDER_PAYMENT","UPDATE_PROPERTY_PAYMENT_METHOD"]),
    changePropertyPayment(property, newValue) {
      this.UPDATE_PROPERTY_ORDER_PAYMENT({ property: property, newValue: newValue });
    },
    changePaymentMethod(value, paymentId) {
      this.UPDATE_PROPERTY_PAYMENT_METHOD({ paymentMethod : value, paymentId: paymentId});
    },
    toCurrency(amount) {
      if (typeof amount !== "number") {
        return amount;
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(amount);
    },
    existError(payment){
      let error = false;
      if (!this.paymentMethods.some(a => a.value === payment.PaymentMethod))
          error = true;

      return error;
    }
  },
};
</script>
