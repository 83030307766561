<template>
    <section>
        <base-list ref="baseList" :totalRecords="totalRecords" @changePage="search">
            <h4 style="display: flex; align-items: center; margin-bottom: 0px" slot="header" class="card-title">
                Pedidos
                <input style="margin-left: 3%" type="checkbox" id="checkbox" v-model="showAllEcommerce"
                    @change="search" />
                <label style="color: #00f1e6;
                        font-size: 12.6px;
                        margin: 0px 15px 0px 10px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;" for="checkbox">
                    Todos os e-commerces
                </label>
            </h4>
            <orders-filter ref="filter" @filter="search" @handleCommand="handleCommand" @requeue="requeueAll"
                @cancel="cancelAll" @changeWarehouses="changeWarehouses" />
            <orders-table ref="table" :total="totalRecords" :filterInstance="$refs.filter" />
        </base-list>
        <router-view></router-view>
    </section>
</template>

<script>
import BaseList from "@/components/BaseList.vue";
import OrdersFilter from "../components/OrdersFilter.vue";
import OrdersTable from "../components/OrdersTable.vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import swal from "sweetalert2";
import constants from "@/util/constants";

export default {
    components: {
        BaseList,
        OrdersFilter,
        OrdersTable
    },
    data() {
        return {
            totalRecords: 0
        }
    },
    computed: {
        ...mapState(["ecommerce"]),
        showAllEcommerce:
        {
            get() {
                return this.$store.state.allEcommerce;
            },
            set(value) {
                this.updateCheckAllEcommerce(value);
            }
        }
    },
    methods: {
        ...mapActions("orders", ["requeueSelected", "getOrders", "exportOrderError", "exportOrderCanceled", "exportOrderAwaitingCancellation", "exportOrderAll", "getErpDocuments", "getHelpCenter", "cancelSelected"]),
        ...mapMutations("orders", ["SET_ORDERS_SELECTED"]),
        ...mapMutations(['SET_ALL_ECOMMERCE']),
        async search() {
            let pagination = this.$refs.baseList.value();

            let filter = this.$refs.filter.value();

            if (this.$store.state.allEcommerce)
                filter = Object.assign({ ecommerceName: "Todos" }, filter);

            await this.getOrders({ currentPage: pagination.pageNumber, perPage: pagination.pageSize, ...filter }).then(res => {
                this.totalRecords = res.data.Total
            });
        },
        requeueAll() {
            swal({
                title: "Tem certeza?",
                text: "Deseja reenfileirar o envio dos pedidos selecionados?",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    let filter = this.$refs.filter.value();

                    let ecommerceName = this.$store.state.allEcommerce ? 'Todos' : this.ecommerce.Name;

                    let requestData = this.$refs.table.selectedAllExisting
                        ? { ecommerceName: ecommerceName, from: filter.from, to: filter.to, status: filter.status, selectAllItens: this.$refs.table.selectedAllExisting, reason: result.value }
                        : { ecommerceName: ecommerceName, orders: [...this.$refs.table.selectedItems], selectAllItens: this.$refs.table.selectedAllExisting, reason: result.value };

                    this.requeueSelected(requestData)
                        .then(async () => {
                            this.$showSuccess("Pedido(s) reenfileirado(s) com sucesso")
                            await this.search();
                        })
                        .catch(() => this.$showError("Ocorreu um erro ao reenfileirar os pedidos"));
                }
            });
        },
        cancelAll() {
            swal({
                title: "Tem certeza?",
                text: "Deseja cancelar o(s) pedido(s) selecionado(s)?",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                buttonsStyling: false,
                input: 'text',
                inputPlaceholder: 'Motivo do cancelamento',
                inputLabel: 'Informe o motivo do cancelamento:',
                inputValidator: (value) => {
                    return !value && 'É necessário informar o motivo do cancelamento!'
                }
            }).then(async (result) => {
                if (result.value) {
                    let filter = this.$refs.filter.value();
                    let ecommerceName = this.$store.state.allEcommerce ? 'Todos' : this.ecommerce.Name;

                    let requestData = this.$refs.table.selectedAllExisting
                        ? { ecommerceName: ecommerceName, from: filter.from, to: filter.to, status: filter.status, selectAllItens: this.$refs.table.selectedAllExisting, reason: result.value }
                        : { ecommerceName: ecommerceName, orders: [...this.$refs.table.selectedItems], selectAllItens: this.$refs.table.selectedAllExisting, reason: result.value };

                    this.cancelSelected(requestData)
                        .then(async () => {
                            this.$showSuccess("Pedido(s) cancelado(s) com sucesso")
                            await this.search();
                        })
                        .catch(() => this.$showError("Ocorreu um erro ao cancelar os pedidos"));
                }
            });
        },
        async exportAll() {
            let filter = this.$refs.filter.value();

            if (this.$store.state.allEcommerce)
                filter = Object.assign({ ecommerceName: "Todos" }, filter);

            await this.exportOrderAll(filter)
                .then(response => {
                    this.downloadFile("pedidos.xlsx", response.data);
                });
        },
        async exportErrors() {
            var filter = this.getFilterToExport();

            await this.exportOrderError(filter)
                .then(response => {
                    this.downloadFile("pedidos-com-erro.xlsx", response.data);
                });
        },
        async exportCanceled() {
            var filter = this.getFilterToExport();

            await this.exportOrderCanceled(filter)
                .then(response => {
                    this.downloadFile("pedidos-cancelados.xlsx", response.data);
                });
        },
        async exportAwaitingCancellation() {
            var filter = this.getFilterToExport();

            await this.exportOrderAwaitingCancellation(filter)
                .then(response => {
                    this.downloadFile("pedidos-aguardando-cancelamento.xlsx", response.data);
                });
        },
        getFilterToExport() {
            let filter = this.$refs.filter.value();

            filter = Object.assign({ orderTypes: [constants.OrderType.Sales] }, filter);

            if (this.$store.state.allEcommerce)
                filter = Object.assign({ ecommerceName: "Todos" }, filter);

            return filter;
        },
        downloadFile(file, fileContent) {
            const url = window.URL.createObjectURL(new Blob([fileContent]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file);
            document.body.appendChild(link);
            link.click();
            return;
        },
        async handleCommand(command) {
            switch (command) {
                case "exportAll":
                    await this.exportAll();
                    break;
                case "exportErrors":
                    await this.exportErrors();
                    break;
                case "exportCanceled":
                    await this.exportCanceled();
                    break;
                case "exportAwaitingCancellation":
                    await this.exportAwaitingCancellation();
                    break;
            }
        },
        changeWarehouses() {
            let orders = this.$refs.table.selectedItems;
            this.SET_ORDERS_SELECTED(orders);
            this.$router.push({ name: "UpdateWarehouse" });
        },
        updateCheckAllEcommerce(value) {
            this.SET_ALL_ECOMMERCE(value);
        }
    },
    async mounted() {
        this.$nextTick(async () => {
            if (!this.$route.query.orderType)
                await this.search();
        });
        await this.getErpDocuments();
        await this.getHelpCenter();
        EventBus.$on("requeue", () => this.requeueAll());
        EventBus.$on("cancel", () => this.cancelAll());
    },
};
</script>
<style>
@import "../Order.css";
</style>
