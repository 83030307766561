<template>
    <el-dialog title="Reenfileirar" :visible.sync="showModal" width="30%" :before-close="handleClose">
        <div>
            <small style="color: gray">* Um pedido por linha</small>
            <div class="d-flex">
                <el-input placeholder="Pedido(s)" rows="5" type="textarea" v-model="number"></el-input>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Reenfileirar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
import { mapActions, mapState } from 'vuex';

export default {
    components: [
        Modal
    ],
    components: { Modal },
    props: {
        showModal: { type: Boolean, default: false }
    },
    data() {
        return {
            number: null,
            orderNumbers: [],
        }
    },
    computed: {
        ...mapState(["ecommerce"]),
    },
    methods: {
        ...mapActions("orders", ["requeueSelected"]),
        handleClose() {
            this.clearNumber();
            this.$emit("close");
        },
        clearNumber() {
            this.number = null;
        },
        async save() {
            if (this.$isNullOrEmpty(this.number)) {
                this.$showError("Nenhum pedido especificado!");
                return;
            }

            this.orderNumbers = this.number.split("\n");

            let orders = this.orderNumbers.map(orderNumber => ({
                number: orderNumber.trim(),
                ecommerceName: this.ecommerce.Name
            }));

            await this.requeueSelected({ ecommerceName: this.ecommerce.Name, orders})
                .then(async () => {
                    this.$showSuccess("Pedido(s) reenfileirado(s) com sucesso")
                })
                .catch(() => this.$showError("Ocorreu um erro ao reenfileirar os pedidos"));

            this.handleClose();
        }
    }
}
</script>
