<template>
    <div class="modal fade" id="logModal" tabindex="-1" role="dialog" aria-labelledby="logModalLabel" aria-hidden="true"
        data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content bg-color-default">
                <div class="modal-header">
                    <h4 slot="header" id="logModalLabel" class="modal-title card-title">
                        Skus do Produto
                    </h4>
                </div>
                <div class="modal-body">
                    <div>
                        <BaseList ref="baseList" :totalRecords="totalRecords" @changePage="searchSkus">
                            <el-table v-loading="loading" :data="skus">
                                <el-table-column align="center" label="SKU" min-width="40" prop="Name">
                                </el-table-column>
                                <el-table-column align="center" label="SKU no SAP" min-width="40" prop="SkuSAP">
                                </el-table-column>
                                <el-table-column align="center" label="ID SKU Ecommerce" min-width="20"
                                    prop="ECommerceId">
                                </el-table-column>
                                <el-table-column align="center" label="Status" min-width="20" prop="Active">
                                    <div slot-scope="props">
                                        <div
                                            :class="props.row.Active ? 'status statusIntegrated' : 'status statusError'">
                                            {{ props.row.Active ? 'Ativo' : 'Inativo' }}
                                        </div>
                                    </div>
                                </el-table-column>
                                <el-table-column :min-width="40" align="center" label="Ações">
                                    <div slot-scope="props">
                                        <base-button slot="reference" class="like !important btn-link" type="primary"
                                            icon @click="showSkusLongTail(props.row.Id)">
                                            <el-tooltip content="Lista de SKUs cauda longa" effect="light"
                                                :open-delay="100" placement="top">
                                                <i class="tim-icons icon-vector">delete_outline</i>
                                            </el-tooltip>
                                        </base-button>
                                    </div>
                                </el-table-column>
                            </el-table>
                        </BaseList>
                    </div>
                    <div align="center">
                        <base-button @click="$router.push({ name: 'ProductList' })" class="btn-custom" type="primary">
                            Voltar
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseList from "@/components/BaseList";
import { mapActions } from "vuex";

export default {
    components: {
        BaseList,
    },
    data() {
        return {
            skus: [],
            productId: null,
            loading: false,
            show: false,
            totalRecords: 0
        }
    },
    methods: {
        ...mapActions("product", ["getSkus"]),
        searchSkus() {
            this.loading = true;
            let paginationFilter = this.$refs.baseList.value();
            this.getSkus({ ...paginationFilter, productId: this.productId }).then(res => {
                this.skus = res.data.Records;
                this.totalRecords = res.data.Total;
                this.loading = false;
            }).catch(() => this.loading = false);
        },
        showSkusLongTail(id) {
            this.$router.push({ name: 'skusLongTail', query: { productId: id } });
        },
    },
    mounted() {
        this.productId = this.$route.query.productId;
        this.getSkus({ pageNumber: 1, pageSize: 5, productId: this.productId }).then(res => {
            this.skus = res.data.Records;
            this.totalRecords = res.data.Total;
            this.loading = false;
        });
        jQuery("#logModal").modal("show");
    },
    destroyed() {
        jQuery('.modal-backdrop').removeClass('modal-backdrop');
    },
};
</script>