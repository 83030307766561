import { mapState } from "vuex";
import constants from '@/util/constants';

export default {
    computed: {
        ...mapState("orders", ["orderCurrent"])
    },
    methods: {
        existErrors(){

            let addressBilling = this.orderCurrent.OrderAddress.find(
                (a) => a.AddressType === constants.AddressType.Billing
            );
            
            let addressShipping = this.orderCurrent.OrderAddress.find(
                (a) => a.AddressType === constants.AddressType.Shipping
            );

            var message = "";

            if (this.orderCurrent.Customer.Name == "")
                message += "<br>Por favor, preencha o campo 'Nome completo' em cliente.";
        
            if (addressBilling.Name == "")
                message += "<br>Por favor, preencha o campo 'Nome' em cobrança.";
        
            if (addressBilling.ZipCode == "")
                message += "<br>Por favor, preencha o campo 'CEP' em cobrança.";
        
            if (addressBilling.Street == "")
                message+="<br>Por favor, preencha o campo 'RUA' em cobrança.";
        
            if (addressBilling.Number == "")
                message+="<br>Por favor, preencha o campo 'NÚMERO' em cobrança.";
        
            if (addressBilling.Neighborhood == "")
                message+="<br>Por favor, preencha o campo 'BAIRRO' em cobrança.";
        
            if (addressBilling.City == "")
                message+="<br>Por favor, preencha o campo 'CIDADE' em cobrança.";
        
            if (addressBilling.Region == "")
                message+="<br>Por favor, preencha o campo 'UF' em cobrança.";
        
            //Shipping
            if (addressShipping.Name == "")
                message+="<br>Por favor, preencha o campo 'NOME' em entrega.";
        
            if (addressShipping.ZipCode == "")
                message+="<br>Por favor, preencha o campo 'CEP' em entrega.";
        
            if (addressShipping.Street == "")
                message+="<br>Por favor, preencha o campo 'RUA' em entrega.";
        
            if (addressShipping.Number == "")
                message+="<br>Por favor, preencha o campo 'NÚMERO' em entrega.";
        
            if (addressShipping.Neighborhood == "")
                message+="<br>Por favor, preencha o campo 'BAIRRO' em entrega.";
        
            if (addressShipping.City == "")
                message+="<br>Por favor, preencha o campo 'CIDADE' em entrega.";
        
            if (addressShipping.Region == "")
                message+="<br>Por favor, preencha o campo 'UF' em entrega.";
        
            if (this.orderCurrent.BplID == "")
                message+="<br>Por favor, preencha o campo 'Código filial'";
        
            if (this.orderCurrent.WarehouseCode == "")
                message+="<br>Por favor, preencha o campo 'Código depósito'";
            
            let errors = message.length > 0;
            
            if(errors){
                this.$message({
                    dangerouslyUseHTMLString: true,
                    message: message
                });
            }

            return errors;
        }  
    },
}