<template>
  <div>
    <el-table :data="products" ref="multipleTable" @selection-change="handleSelectionChange">
      <template slot="append">
        <el-row v-if="selectedItems.length > 0">
          <el-col class="table-selection-info">
            <span>{{ selectedMessage }}</span>
            <el-link v-if="selectedAllExisting" @click="clearSelected">Limpar seleção.</el-link>
            <el-link v-else @click="selectedAllExisting = true">Selecionar todos os {{ total }} pedidos.</el-link>
          </el-col>
        </el-row>
      </template>
      <el-table-column type="selection" width="60" />
      <el-table-column label="Id" min-width="30" align="center" prop="id"></el-table-column>
      <el-table-column label="Imagem" min-width="50" align="center" prop="image">
        <template slot-scope="scope">
          <img v-if="scope.row.image" class="img-thumbnail" :src="scope.row.image" />
          <img v-else class="img-thumbnail"
            src="https://cdn.vectorstock.com/i/preview-1x/48/06/image-preview-icon-picture-placeholder-vector-31284806.jpg" />
        </template>
      </el-table-column>
      <el-table-column label="Nome" align="center" min-width="100" prop="name">
      </el-table-column>
      <el-table-column label="ID E-commerce" align="center" prop="idEcommerce">
      </el-table-column>
      <el-table-column min-width="40" label="Skus" align="center">
       <template slot-scope="scope">
          <p v-for="(sku, index) in scope.row.skus" :key="index">{{ sku.SkuSAP}}</p>
        </template>
      </el-table-column>
      <el-table-column label="Tipo de Produto" align="center" prop="type">
      </el-table-column>
      <el-table-column label="Status" min-width="50" align="center" prop="status">
        <template slot-scope="scope">
          <div :class="getClassStatus(scope.row.status)">{{ scope.row.status }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.id)">
            <i class="material-icons">mode_edit_outline</i>
          </base-button>
          <base-button
              class="edit btn-link"
              type="danger"
              size="sm"
              icon
              @click="remove(props.row.id)"
            >
              <i class="material-icons">delete_outline</i>
            </base-button>
          <base-button slot="reference" class="like !important btn-link" type="primary" size="sm" icon
            @click="showSkus(props.row.id)">
            <el-tooltip content="Lista de SKUs" effect="light" :open-delay="100" placement="top">
              <ListBullet />
            </el-tooltip>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <router-view></router-view>
  </div>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import { mapActions } from 'vuex';
import swal from "sweetalert2";

export default {
  components: {
    ListBullet
  },
  props: {
    total: { type: Number },
    products: {
      type: Array,
      default: function () {
        return [{
          id: "",
          name: "",
          skus: [],
          idEcommerce: 0,
          type: "",
          status: ""
        }];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      skus: [],
      selectedItems: [],
      selectedAllExisting: false,
    };
  },
  computed: {
    selectedMessage() {
      return this.selectedAllExisting
        ? `Todos os ${this.total} pedidos foram selecionados.`
        : `${this.selectedItems.length} pedidos selecionados.`;
    }
  },
  methods: {
    ...mapActions('product', ['deleteProduct']),
    getClassStatus(status) {
      return status === 'Ativo' ? "status statusIntegrated" : "status statusError";
    },
    edit(id) {
      this.$router.push({ name: "ProductEdit", params: { id: id } });
    },
    showSkus(id) {
      this.$router.push({ name: 'skus', query: { productId: id } });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.selectedItems = val.map((x) => x.id);
    },
    value() {
      return this.selectedAllExisting ? {} : { ids: this.selectedItems };
    },
    async remove(id) {
      swal({
        title: "Deseja excluir este produto?",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.deleteProduct(id)
            .then(() => {
              this.$showSuccess("Produto excluido com sucesso!");
              this.$emit("reload");
            });
        }
      });
    },
    clearSelected() {
      this.$refs.multipleTable.clearSelection();
      this.selectedAllExisting = false;
      this.selectedItems = [];
    },
  },
};
</script>
<style scoped>
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: inline-block;
  width: 60px;
  height: auto;
  background-color: transparent !important;
}

.my-bg-header {
  background-color: #27293d !important;
}

.el-avatar img {
  height: auto !important;
}
</style>
