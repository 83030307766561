<template>
    <el-table :data="orderCurrent.Products">
        <el-table-column label="SKU" prop="SKU">
            <template slot-scope="scope">
                <base-input>
                    <el-input style="text-align:center" v-model="scope.row.SKU"
                        :readonly="!shouldDisable()"></el-input>
                </base-input>
            </template>
        </el-table-column>
        <el-table-column label="Nome" prop="Name">
            <template slot-scope="scope">
                <base-input>
                    <el-input style="text-align:center" v-model="scope.row.Name"
                        :readonly="!shouldDisable()"></el-input>
                </base-input>
            </template>
        </el-table-column>
        <el-table-column align="center" label=" Preço Unitário" prop="Price">
            <template slot-scope="scope">
                {{ scope.row.Price | toCurrency }}
            </template>
        </el-table-column>
        <el-table-column align="center" label="Quantidade" prop="Quantity">
        </el-table-column>
        <el-table-column align="center" label="Depósito" prop="WarehouseCode">
        </el-table-column>
        <el-table-column align="center" label="Total" prop="Total">
            <template slot-scope="scope">
                {{ (scope.row.Price * scope.row.Quantity) | toCurrency }}
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import { mapState } from 'vuex'

export default {
    props : {
        configurationChangeSkuProduct : { type : Object }
    },
    computed: {
        ...mapState("orders", ["orderCurrent"]),
    },
    methods: {
        shouldDisable() {
            return ( this.configurationChangeSkuProduct !== null &&
                this.configurationChangeSkuProduct.Value === 'true' &&
                this.orderCurrent.Status.BahnReference === 'error' &&
                this.orderCurrent.DocEntry === 0
            );
        },
    },
}
</script>