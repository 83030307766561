<template>
    <base-list
    :totalRecords="totalRecords"
    @changePage="search"
    ref="baseList"
    title="Pedidos sem integração"
    info="Erros de pedidos não integrados ao Bahn.">
        <LogOrderFilter ref="filter" @filter="search"/>
        <LogOrderTable :logOrders="logOrders" />
    </base-list>
</template>
<script>
import BaseList from "@/components/BaseList"
import { mapActions, mapState } from "vuex";
import LogOrderFilter from "../components/LogOrderFilter.vue"
import LogOrderTable from "../components/LogOrderTable.vue"

export default {
    data() {
        return {
            totalRecords : 0
        }
    },
    components : {
        BaseList,
        LogOrderFilter,
        LogOrderTable
    },
    computed : {
        ...mapState("logOrder",["logOrders"])
    },
    methods: {
        ...mapActions("logOrder",["getLogOrders"]),
        async search(){
            let pagination = this.$refs.baseList.value();
            let filter = this.$refs.filter.value();

            await this.getLogOrders({...pagination, ...filter}).then(res => {
                this.totalRecords = res.data.Total
            });
        },
    },
    async mounted() {
        await this.search();
    },
}
</script>
<style scoped>

</style>