<template>
  <div class="row" style="float: right">
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.From"></el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.To"></el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Pedido" v-model="filters.OrderNumber">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.Username" placeholder="Usuário"
          filterable>
          <el-option class="select-primary" v-for=" item  in  users " :key="item.username" :label="item.username"
            :value="item.username"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <button class="custom-btn-add" @click="Export">Exportar</button>
      </base-input>
    </span>
  </div>
</template>
<script>

import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState("logOrder", ["users"])
  },
  data() {
    return {
      filters: {
        From: null,
        To: null,
        OrderNumber: null,
        Username: null
      }
    };
  },
  methods: {
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        From: null,
        To: null,
        OrderNumber: null
      };
      this.$emit("filter");
    },
    value() {
      if (this.filters.From != "" && this.filters.From != null) {
        this.filters.From = moment(this.filters.From).format("YYYY-MM-DD") + " 00:00:00";
      }
      if (this.filters.To != "" && this.filters.To != null) {
        this.filters.To = moment(this.filters.To).format("YYYY-MM-DD") + " 23:59:59";
      }

      return this.filters;
    },
    async Export() {
      this.$emit("export");
    },
  },
};
</script>
