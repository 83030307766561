<template>
    <div class="row col-12 mt-2 mx-3" v-if="isDeadlineReached">
        <p class="row text-danger">Data limite atingida para visualizar informações restritas</p>
    </div>
</template>

<script>
export default {
    name: 'deadline-alert',
    props: {
        isDeadlineReached: {
            type: Boolean,
            default: false
        }
    }
}
</script>