<template>
    <UpdateLog :dataLog="logs" />
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UpdateLog from "../../../components/UpdateLog.vue"

export default {
    components: {
        UpdateLog
    },
    data() {
        return {
            logs: [],
        }
    },
    computed: {
        ...mapState("fulfillment", ["fulfillmentCurrent"]),
    },
    methods: {
        ...mapActions("fulfillment", ["getFulfillmentLogs"]),
        search() {
            this.getFulfillmentLogs(this.fulfillmentCurrent.Id)
                .then((res) => this.logs = res.data)
                .catch((error) => this.$showError(error))
        }
    },
    mounted() {
        this.search();
    },
}
</script>