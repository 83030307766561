<template>
  <div class="row col-12 mx-0 mt-3 p-0 d-flex justify-content-between">
    <div class="row col-md-6 m-0 p-0 pl-3">
      <base-input class="col-md-7 pb-2">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Descrição" v-model="filters.name" @keyup.enter.native="filter">
        </el-input>
      </base-input>
      <base-input class="col-md-4 pb-2">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Código" v-model="filters.code" @keyup.enter.native="filter">
        </el-input>
      </base-input>
    </div>
    <div className="col-md-6 justify-content-end m-0 p-0 d-flex">
      <div className="m-0 p-0">
        <button class="custom-btn-filter mx-2" @click="filter">Filtrar</button>
        <button class="custom-btn-filter mx-2" @click="clearFilter">Limpar filtro</button>
        <button v-if="getUserPermission('34_c')" class="custom-btn-add mx-2" @click="addNew">Novo</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filters: {
        id: null,
        name: null,
        code: null
      }
    };
  },
  methods: {
    addNew() {
      this.$router.push({ name: "PriceListCreate" });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        id: null,
        name: null,
        code: null
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
};
</script>
