<template>
    <el-dialog title="Atualizar Depósito" :visible.sync="showModalWarehouse" width="30%" :before-close="handleClose">
        <div>
            <div>
                <base-input>
                    <el-select class="select-primary" v-model="warehouseCode" placeholder="Código de Depósito">
                        <el-option class="select-primary" v-for="(item, index) in warehouses" :key="index"
                            :label="item.Code" :value="item.Code"></el-option>
                    </el-select>
                </base-input>
            </div>
             <small style="color: gray">* Um pedido por linha</small>
            <div class="d-flex">
                <el-input placeholder="Pedido(s)" rows="5" type="textarea" v-model="number"></el-input>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Atualizar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
import { mapActions } from 'vuex';

export default {
    components: [
        Modal
    ],
    components: { Modal },
    props: {
        showModalWarehouse: { type: Boolean, default: false }
    },
    data() {
        return {
            number: null,
            orderNumbers: [],
            warehouses: [],
            warehouseCode: null
        }
    },
    methods: {
        ...mapActions("orders", ["updateWarehouse"]),
        ...mapActions("configurations", ["getWarehouses"]),
        handleClose() {
            this.clearNumber();
            this.$emit("close");
        },
        clearNumber() {
            this.number = null;
            this.warehouseCode=null;
        },
        async save() {
            if (this.$isNullOrEmpty(this.warehouseCode)) {
                this.$showError("Selecionde um depósito!");
                return;
            }

            this.orderNumbers = this.number.split("\n");

            this.loading = true;
            await this.updateWarehouse({ numbers: this.orderNumbers, warehouseCode: this.warehouseCode })
            .then(() => {
                this.$showSuccess("Atualização de depósitos realizada com sucesso!");
            })
            .catch(error => {
                this.$showError(error.response.data)
            })            
            
            this.loading = false
            this.handleClose();
        },
        async setWarehouses()
        {
            this.getWarehouses()
                .then(res => {
                    this.warehouses = res.data.filter(warehouse => warehouse.IsIntegration);
                })
                .catch(error => { this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`) });
        }
    },
    async mounted() 
    {        
        await this.setWarehouses();
    },
}
</script>
