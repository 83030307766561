<template>
  <div class="modal fade" id="logModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="logModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content bg-color-default">
        <div class="modal-header">
          <h4 slot="header" id="logModalLabel">
            Logs de integração
          </h4>
          <button type="button" class="close" @click="closeModal">
            <span aria-hidden="true" class="text-white">
              <i class="tim-icons icon-simple-remove"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <el-table v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.0)" :data="logs" max-height="370px">
              <el-table-column label="Central de Ajuda" min-width="140" align="center">
                <div slot-scope="props">
                  <el-link v-if="computedUrlHelpCenter(props.row.message)"
                    :href="computedUrlHelpCenter(props.row.message)" target="_blank" type="warning">
                    Ajuda ?
                  </el-link>
                  <a v-else href="#" class="nav-item dropdown-item disabled">
                    -
                  </a>
                </div>
              </el-table-column>
              <el-table-column label="Mensagem" align="left" min-width="500">
                <div slot-scope="props">
                  <span v-html="props.row.message"></span>
                </div>
              </el-table-column>
              <el-table-column label="Data" min-width="165" align="center" prop="dateTime">
                <template slot-scope="scope">
                  {{ scope.row.dateTime | toDateTime }}
                </template>
              </el-table-column>
              <el-table-column :min-width="130" label="Status">
                <div slot-scope="props">
                  <div :class="getClass(props.row.status)">
                    {{ props.row.status | nameStatus }}
                  </div>
                </div>
              </el-table-column>
            </el-table>
            <div class="float-right">
              <router-link :to="{
                path: '/message',
                query: { orderNumber: number },
              }" class="btn-link" type="primary"
                onclick="jQuery('.modal-backdrop').removeClass('modal-backdrop'); return true;">
                <base-button class="btn-link" type="primary">
                  Ver todos >
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    Modal
  },
  data() {
    return {
      show: false,
      logs: [],
      id: null,
      number: null,
      loading: false,
      ecommerceName: null,
    }
  }, computed: {
    ...mapState('orders', ['helpCenter']),
    ...mapState(['ecommerce']),
    computedUrlHelpCenter() {
      return (message) => {
        if(this.helpCenter){
          let urlHelpCenter = this.helpCenter.find(mapeamento => message.includes(mapeamento.Code));
          return urlHelpCenter ? urlHelpCenter.Url : null;
        }
        
        return "";
      }
    }
  },
  methods: {
    ...mapActions("orders",["getLastFiveLogs"]),
    closeModal() {
      $(".modal-backdrop").hide();
      this.$router.go(-1);
    },
    closeModalOnOutsideClick(event) {
      if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
        this.closeModal();
      }
    },
    async getLogs() {
      await this.getLastFiveLogs({ number: this.number, ecommerceName: this.ecommerceName})
        .then(res => {
          this.logs = res.data.Items;
        })
        .catch(error => { this.$showError(error.response.data) })
    },
    goToLogs() {
      this.$router.push({ name: "OrderDetail", params: { id: this.id } });
    },
    getClass(status) {
      const statusParams = {
        Error: "statusError",
        Started: "statusComplete",
        Success: "statusIntegrated",
        Canceled: "statusCanceled",
        Delete: "statusCanceled",
        Information: "statusProcessing",
      };

      return statusParams[status] ? `status ${statusParams[status]}` : "status statusDefault";
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.number = this.$route.query.number;
    this.ecommerceName = this.$route.query.ecommerceName;
    this.getLogs();
    setTimeout(() => {
      document.addEventListener('click', this.closeModalOnOutsideClick);
      jQuery("#logModal").modal("show");
    }, 500);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeModalOnOutsideClick);
  },
}
</script>
<style>
@import "../Order.css";
</style>
