<template>
  <BaseList :title="title" ref="baseList" :totalRecords="totalRecords" @changePage="searchOrganizations">
    <OrganizationsFilter ref="filter" @filter="searchOrganizations" />
    <OrganizationsTable :organizations="organizationsToTable" @evtRefresh="searchOrganizations" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import OrganizationsTable from "@/pages/Organization/components/OrganizationTable";
import OrganizationsFilter from "@/pages/Organization/components/OrganizationFilter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseList,
    OrganizationsTable,
    OrganizationsFilter,
  },
  data() {
    return {
      title: "Organizações",
      totalRecords: 0
    };
  },
  computed: {
    ...mapGetters("organization", ["organizationsToTable"])
  },
  methods: {
    ...mapActions("organization", ["getOrganizations"]),
    async searchOrganizations() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getOrganizations({ ...paginationFilter, ...listFilter })
        .then((response) => this.totalRecords = response.data.Total);
    },
  },
  async mounted() {
    await this.searchOrganizations();
  },
};
</script>
