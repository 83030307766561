<template>
  <div class="row ml-2 mr-2">
    <deadlineAlert :isDeadlineReached="isDeadlineReached()" />
    <div class="col-lg-6 col-sm-12">
      <card class="stacked-form">
        <h4 slot="header" class="card-title">Endereço de Entrega</h4>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-9">
                <base-input label="Nome completo" v-model="shippingAddress.Name"
                  @input="changePropertyShipping('Name', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
              <div class="col-12 col-lg-3">
                <base-input label="CEP" v-model="shippingAddress.ZipCode"
                  @input="changePropertyShipping('ZipCode', $event)" maxlength="8"
                  :readonly="isReadonlyData()"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-9 col-lg-6">
                <base-input label="Rua" v-model="shippingAddress.Street"
                  @input="changePropertyShipping('Street', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
              <div class="col-3 col-lg-2">
                <base-input label="N°" v-model="shippingAddress.Number"
                  @input="changePropertyShipping('Number', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
              <div class="col-12 col-lg-4">
                <base-input label="Complemento" v-model="shippingAddress.Complement"
                  @input="changePropertyShipping('Complement', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-5">
                <base-input label="Bairro" v-model="shippingAddress.Neighborhood"
                  @input="changePropertyShipping('Neighborhood', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
              <div class="col-9 col-lg-5">
                <base-input label="Cidade" v-model="shippingAddress.City"
                  @input="changePropertyShipping('City', $event)" :readonly="isReadonlyData()"></base-input>
              </div>
              <div class="col-3 col-lg-2">
                <base-input label="UF" v-validate="'length:2'" v-model="shippingAddress.Region"
                  @input="changePropertyShipping('Region', $event)" maxlength="2" :readonly="isReadonlyData()">
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-lg-6 col-sm-12">
      <card class="stacked-form">
        <h4 slot="header" class="card-title">Endereço de Pagamento</h4>
        <form>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg-9">
                  <base-input label="Nome completo" v-model="billingAddress.Name"
                    @input="changePropertyPayment('Name', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-12 col-lg-3">
                  <base-input label="CEP" v-model="billingAddress.ZipCode"
                    @input="changePropertyPayment('ZipCode', $event)" maxlength="8"
                    :readonly="isReadonlyData()"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-9 col-lg-6">
                  <base-input label="Rua" v-model="billingAddress.Street"
                    @input="changePropertyPayment('Street', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-3 col-lg-2">
                  <base-input label="N°" v-model="billingAddress.Number"
                    @input="changePropertyPayment('Number', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-12 col-lg-4">
                  <base-input label="Complemento" v-model="billingAddress.Complement"
                    @input="changePropertyPayment('Complement', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-5">
                  <base-input label="Bairro" v-model="billingAddress.Neighborhood"
                    @input="changePropertyPayment('Neighborhood', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-9 col-lg-5">
                  <base-input label="Cidade" v-model="billingAddress.City"
                    @input="changePropertyPayment('City', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-3 col-lg-2">
                  <base-input label="UF" v-model="billingAddress.Region" maxlength="2"
                    @input="changePropertyPayment('Region', $event)" :readonly="isReadonlyData()"></base-input>
                </div>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import constants from "@/util/constants";
import deadlineAlert from "@/components/AnonymizationDeadlineAlert.vue";
export default {
  components: {
    deadlineAlert
  },
  computed: {
    ...mapState("orders", ["orderCurrent"]),
    ...mapState("health", ["displayRestrictedData"]),
    billingAddress() {
      if (this.orderCurrent && this.orderCurrent.OrderAddress)
        return this.orderCurrent.OrderAddress.find(
          (a) => a.AddressType === constants.AddressType.Billing
        );

      return {
        Name: "",
        ZipCode: null,
        Street: null,
        Number: null,
        Complement: null,
        Neighborhood: null,
        Country: null,
        City: null,
        Region: null
      };
    },
    shippingAddress() {
      if (this.orderCurrent && this.orderCurrent.OrderAddress)
        return this.orderCurrent.OrderAddress.find(
          (a) => a.AddressType === constants.AddressType.Shipping
        );

      return {
        Name: "",
        ZipCode: null,
        Street: null,
        Number: null,
        Complement: null,
        Neighborhood: null,
        Country: null,
        City: null,
        Region: null
      };
    }
  },
  methods: {
    ...mapMutations("orders", [
      "UPDATE_PROPERTY_ORDER_SHIPPING",
      "UPDATE_PROPERTY_ORDER_PAYMENT",
    ]),
    changePropertyPayment(property, newValue) {
      this.UPDATE_PROPERTY_ORDER_PAYMENT({ property: property, newValue: newValue });
    },
    changePropertyShipping(property, newValue) {
      this.UPDATE_PROPERTY_ORDER_SHIPPING({ property: property, newValue: newValue });
    },
    isReadonlyData() {
      return !this.displayRestrictedData || this.orderCurrent.Anonymized;
    },
    isDeadlineReached() {
      return this.displayRestrictedData && this.orderCurrent.Anonymized;
    }
  }
};
</script>