<template>
  <div class="pa-3">
    <h4 slot="header" class="card-title">
      <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
        <i class="material-icons">arrow_back</i>
      </base-button>
      Detalhes do pedido
    </h4>
    <div class="row">
      <div class="col-6 d-flex justify-content-start">
        <button class="custom-flexible-btn ml-3 mr-3" @click="expandAll">{{ buttonName }}</button>
        <button v-if="getUserPermission('00_c')" class="custom-flexible-btn mr-3 d-flex align-items-center"
          @click="showModalCustomFields()"><i class="material-icons mr-2">tune</i> Campos
          Customizados</button>
        <button class="custom-flexible-btn mr-3 d-flex align-items-center" v-if="showEvents"
          @click="showModalOrderEvents()"><i class="tim-icons icon-send mr-2"></i> Eventos</button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button v-if="getUserPermission('29_c') && (orderCurrent.Status.BahnReference == 'error')"
          class="custom-btn-save mr-3" @click="updateModal">
          Atualizar
        </button>
        <button v-if="canRetransfer" class="custom-btn-save mr-3" @click="transferModal">
          Transferência
        </button>
        <OrderCancel :order="orderCurrent" buttonText="Cancelar" />
      </div>
    </div>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <h4><i class="header-icon el-icon-info"></i> Dados Gerais </h4>
        </template>
        <OrderDetailGeneral :shippingMethods="shippingMethods" :paymentMethods="paymentMethods"
          :shippingMethodNotFound="error.shippingMethodNotFound" :changeWarehouse="changeWarehouse" />
      </el-collapse-item>
      <el-collapse-item name="4" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-money"></i> Pagamentos </h4>
        </template>
        <OrderDetailPayment :paymentMethods="paymentMethods" />
      </el-collapse-item>
      <el-collapse-item name="2" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-user"></i> Cliente </h4>
        </template>
        <OrderDetailClient :useNuuk="useNuuk" :isTaxFree="isTaxFree" :stateRegistryTemp="stateRegistryTemp" />
      </el-collapse-item>
      <el-collapse-item name="3" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-location-outline"></i> Endereços </h4>
        </template>
        <OrderDetailAddress />
      </el-collapse-item>
      <el-collapse-item name="5" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-takeaway-box"></i> Produto(s) </h4>
        </template>
        <OrderDetailProduct :configurationChangeSkuProduct="changeSkuProduct" />
      </el-collapse-item>
      <el-collapse-item v-if="showOrderItemTransfer" name="6" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-takeaway-box"></i> Itens de Transferência </h4>
        </template>
        <el-table :data="orderItemTransfer">
          <el-table-column label="SKU" prop="Sku"></el-table-column>
          <el-table-column label="Depósito" prop="WarehouseCode">
          </el-table-column>
          <el-table-column label="Preço Unitário" prop="UnitPrice">
            <template slot-scope="scope">
              {{ scope.row.UnitPrice | toCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Quantidade" prop="Quantity">
          </el-table-column>
          <el-table-column label="Total" prop="Total">
            <template slot-scope="scope">
              {{ (scope.row.UnitPrice * scope.row.Quantity) | toCurrency }}
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
      <el-collapse-item name="7" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-tickets"></i> Logs </h4>
        </template>
        <UpdateLog :dataLog="logs" />
      </el-collapse-item>
    </el-collapse>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import OrderDetailGeneral from '../components/OrderDetailGeneral.vue';
import constants from '../../../util/constants';
import UpdateLog from '../../../components/UpdateLog.vue';
import OrderDetailAddress from '../components/OrderDetailAddress.vue';
import OrderDetailClient from '../components/OrderDetailClient.vue';
import OrderDetailProduct from '../components/OrderDetailProduct.vue';
import OrderDetailPayment from '../components/OrderDetailPayment.vue';
import transferOrderMixin from "src/mixins/transferOrderValidateMixin.js";
import OrderCancel from '../components/OrderCancel.vue';
import swal from "sweetalert2";
import { EventBus } from "@/eventBus";

export default {
  mixins: [transferOrderMixin],
  components: {
    OrderDetailGeneral,
    UpdateLog,
    OrderDetailAddress,
    OrderDetailClient,
    OrderDetailProduct,
    OrderDetailPayment,
    OrderCancel
  },
  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5", "6", "7"],
      test: "",
      error: {
        paymentError: false,
        shippingMethodNotFound: false
      },
      shippingMethods: [],
      paymentMethods: [],
      changeWarehouse: false,
      showOrderItemTransfer: false,
      logs: [],
      orderItemTransfer: [],
      changeSkuProduct: null,
      useNuuk: null,
      queryParams: {
        Number: null,
        EcommerceName: null
      },
      logStockError: null,
      canRetransfer: false,
      isTaxFree: false,
      stateRegistryTemp: '',
      showEvents: false
    }
  },
  computed: {
    ...mapState("orders", ["orderCurrent"]),
    ...mapState("health", ["displayRestrictedData"]),
    buttonName() {
      return this.activeNames.length > 0 ? "Recolher Todos" : "Expandir Todos";
    }
  },
  methods: {
    ...mapActions("orders", ["getOrderByNumber", "updateOrder", "getItemTransfer", "retransfer", "getLastLogError"]),
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    expandAll() {
      this.activeNames.length > 0 ? this.activeNames = [] : this.activeNames = ["1", "2", "3", "4", "5"];
    },
    showModalCustomFields() {
      this.$router.push({ path: `${this.orderCurrent.Number}/order-custom-fields` });
    },
    showModalOrderEvents() {
      this.$router.push({ path: `${this.orderCurrent.Number}/order-events` });
    },
    async getShippingMethods() {
      let response = await this.$bahngleis
        .get(`ShippingMethod/GetByEcommerce/`, {
          params: {
            ecommerceName: this.orderCurrent.EcommerceName
          }
        });

      this.shippingMethods = response.data.map(a => {
        return {
          name: a.Name,
          value: a.Name
        }
      });

      if (!this.shippingMethods.some(a => a.value === this.orderCurrent.Shipping.Method)) {
        this.error.shippingMethodNotFound = true;
        this.shippingMethods.push({ name: this.orderCurrent.Shipping.Method, value: this.orderCurrent.Shipping.Method, });
      }
    },
    async getPaymentMethods() {
      let response = await this.$bahngleis
        .get(`PaymentMethod/GetByEcommerce/`, {
          params: {
            ecommerceName: this.orderCurrent.EcommerceName
          }
        });

      this.paymentMethods = response.data.map(a => {
        return {
          name: a.Name,
          value: a.Name
        }
      });
    },
    checkChangeWarehouse(configurationChangeWarehouseCode) {
      this.changeWarehouse = (configurationChangeWarehouseCode !== null && configurationChangeWarehouseCode.Value === 'true') &&
        (this.orderCurrent.Status.BahnReference == 'error' || this.orderCurrent.Status.BahnReference == 'holded') &&
        !this.orderCurrent.ContainsItensTransfer
    },
    checkShowOrderItemTransfer(configurationShowOrderItemTransfer) {
      if (!configurationShowOrderItemTransfer)
        return;

      this.showOrderItemTransfer = configurationShowOrderItemTransfer !== null && configurationShowOrderItemTransfer.Value === 'true' ? true : false;
    },
    async getLogs() {
      await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.orderCurrent.Id +
        `&entity=Order&displayRestrictedData=${this.displayRestrictedData}`
      ).then(response => { this.logs = response.data });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    validateCustomerReseller() {
      if (this.orderCurrent.Customer.Reseller) {
        this.stateRegistryTemp = this.orderCurrent.Customer.Reseller.StateRegistry;
        this.isTaxFree = this.stateRegistryTemp && this.orderCurrent.Customer.Reseller.StateRegistry.toUpperCase() === "ISENTO" ? true : false
      }
    },
    async validationRetransfer() {
      if (this.getUserPermission('29_c')
        && this.orderCurrent.Status.BahnReference == 'error'
        && this.orderCurrent.DocEntryNfTransfer != 0
        && this.orderCurrent.DocEntryPurchaseDeliveryTransfer == 0
        && this.orderCurrent.DocEntryPurchaseInvoiceTransfer == 0) {
        this.canRetransfer = true;
        return;
      }

      if (this.getUserPermission('29_c')
        && this.orderCurrent.Status.BahnReference == 'error'
        && (this.logStockError != null && this.logStockError.ErrorType === 1)
        && this.orderCurrent.DocEntryNfTransfer != 0
        && this.orderCurrent.DocEntryPurchaseDeliveryTransfer != 0
        && this.orderCurrent.DocEntryPurchaseInvoiceTransfer != 0) {
        this.canRetransfer = true;
        return;
      }

      this.canRetransfer = false;
    },
    update(reason) {
      this.orderCurrent.UpdateReason = reason;

      this.updateOrder(this.orderCurrent).then(() => {
        this.$bahngleis.get(`/dashboard/RequeueOrder?ecommerceName=${this.orderCurrent.EcommerceName}&orderId=${this.orderCurrent.Id}`);
        this.$showSuccess("Dados atualizados com sucesso.");
        this.$router.go(-1);
      })
        .catch((error) => this.$showError(error))
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.orderCurrent.EcommerceName,
        keys: [constants.Configurations.ChangeWarehouseCode,
        constants.Configurations.ShowOrderItemTransfer,
        constants.Configurations.ChangeSkuProduct,
        constants.Configurations.UseNuuk,
        constants.Configurations.TrackingSourceName]
      };

      await this.getConfigurationByKeys(query).then(response => {
        this.checkChangeWarehouse(response.data.find(a => a.Key === constants.Configurations.ChangeWarehouseCode));
        this.checkShowOrderItemTransfer(response.data.find(a => a.Key === constants.Configurations.ShowOrderItemTransfer));
        this.changeSkuProduct = response.data.find(a => a.Key === constants.Configurations.ChangeSkuProduct);
        this.useNuuk = response.data.find(a => a.Key === constants.Configurations.UseNuuk);
        if (response.data.find(a => a.Key === constants.Configurations.TrackingSourceName) &&
          response.data.find(a => a.Key === constants.Configurations.TrackingSourceName).Value === constants.ExternalAPI) {
          this.showEvents = true;
        }
      });
    },
    async getOrderItemTransfer() {
      if (this.showOrderItemTransfer) {
        await this.getItemTransfer(this.orderCurrent.Id).then(response => {
          this.orderItemTransfer = response.data;
          this.activeNames.push("6");
        })
          .catch(error => this.$showError(error))
      }
    },
    validate() {
      let isValidateSku = this.orderCurrent.Products.every(product => product.SKU.trim() !== '');
      let isValidateName = this.orderCurrent.Products.every(product => product.Name.trim() !== '');

      if (!isValidateSku) {
        this.$showError("Campo SKU do Produto(s) é obrigatório.");
        return false;
      }

      if (!isValidateName) {
        this.$showError("Campo Nome do Produto(s) é obrigatório.");
        return false;
      }

      return true;
    },
    updateModal() {
      if (!this.validate())
        return;

      if (!this.displayRestrictedData) {
        swal({
          title: "Ops!",
          text: "Não é possível atualizar quando os dados estão anonimizados.",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          buttonsStyling: false
        });
        return;
      }

      swal({
        title: "Confirmar atualização?",
        text: "Após a confirmação o pedido será processado automaticamente.",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        buttonsStyling: false,
        input: 'text',
        inputPlaceholder: 'Motivo da atualização',
        inputLabel: 'Deseja fornecer um motivo para a atualização?',
      }).then(async (result) => {
        if (result.dismiss !== 'cancel') {
          const reason = result.value || '';
          await this.update(reason);
        }
      });
    },
    transferModal() {
      swal({
        title: "Documentos de transferência já criados, deseja criar novamente?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.transfer();
        }
      });
    },
    async transfer() {
      if (this.existErrors())
        return;

      await this.retransfer(this.orderCurrent).then(res => {
        this.$bahngleis.get(`/dashboard/RequeueOrder?ecommerceName=${this.orderCurrent.EcommerceName}&orderId=${this.orderCurrent.Id}`);
        this.$showSuccess("Dados atualizados com sucesso.");
        this.$router.push({ name: 'Orders' });
        this.queryParams.Number = this.orderCurrent.Number;
      })
        .catch(() => this.$showError("Problema ao realizar a atualização."));
    },
    async getLastStockLogError() {
      this.queryParams.Number = this.orderCurrent.Number;
      this.queryParams.EcommerceName = this.orderCurrent.EcommerceName;

      await this.getLastLogError(this.queryParams).then(response => {
        this.logStockError = response.data;
      })
        .catch(error => this.$showError(error));
    },
    async init(showError = true) {
      let number = this.$route.params.id;
      let ecommerceName = this.$route.params.ecommerceName;
      let validateIfHasItemTransfer = true;
      let includeErpShippingReference = true;
      let displayRestrictedData = this.displayRestrictedData;

      if (!number || !ecommerceName) return;

      try {
        await this.getOrderByNumber({ number, ecommerceName, validateIfHasItemTransfer, includeErpShippingReference, displayRestrictedData }).then(async () => {
          await this.getShippingMethods();
          await this.getPaymentMethods();
          await this.getConfigurations();
          await this.getLogs();
          await this.getOrderItemTransfer();
          await this.getLastStockLogError();
          await this.validationRetransfer();
          this.validateCustomerReseller();
        });
      } catch (error) {
        if (showError)
          this.$showError(error);
      }
    },
  },
  async mounted() {
    EventBus.$on("toggleRestrictedDataDisplay", async () => {
      await this.init(false);
    });

    await this.init();
  },
}
</script>
<style>
.el-collapse-item__wrap {
  background-color: #27293d;
}

.el-collapse-item__header {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header.is-active {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header:hover {
  color: white;
  transition: background-color 0.5s ease;
}

.el-collapse {
  border-color: rgba(225, 225, 225, 0.187);
}

.el-icon-arrow-right:before {
  color: white;
}

.el-collapse-item__content {
  color: white;
}
</style>
