<template>
    <div class="row" style="float: right">
        <span style="margin: 0 10pt">
            <base-input>
                <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.From"></el-date-picker>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.To"></el-date-picker>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Pedido" v-model="filters.OrderNumber">
                </el-input>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="filter">Filtrar</button>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="clearFilter">
                Limpar filtro
            </button>
        </span>
    </div>
</template>
<script>

export default {
    data() {
        return {
            filters: {
                From: null,
                To: null,
                OrderNumber: null
            }
        };
    },
    methods: {
        filter() {
            this.$emit("filter");
        },
        clearFilter() {
            this.filters = {
                From: null,
                To: null,
                OrderNumber: null
            };
            this.$emit("filter");
        },
        value() {
            return this.filters;
        }
    },
};
</script>