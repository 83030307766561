<template>
    <BaseList
      :title="title"
      ref="baseList"
      :totalRecords="totalRecords"
      @changePage="searchGroups"
    >
      <AttributesFilter ref="filter" @filter="searchGroups" />
      <AttributesTable :attributes="attributesToTable" @reload="searchGroups" />
    </BaseList>
  </template>
  <script>
  import BaseList from "@/components/BaseList";
  import AttributesTable from "@/pages/Product/components/AttributesTable";
  import AttributesFilter from "@/pages/Product/components/AttributesFilter";
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    components: {
      BaseList,
      AttributesTable,
      AttributesFilter,
    },
    data() {
      return {
        title: "Lista de Atributos",
        totalRecords: 0
      };
    },
    computed: {
      ...mapGetters("product", ["attributesToTable"])
    },
    methods: {
      ...mapActions("product", ["getAttributes"]),
      async searchGroups() {
        let paginationFilter = this.$refs.baseList.value();
        let listFilter = this.$refs.filter.value();
        await this.getAttributes({ ...paginationFilter, ...listFilter })
        .then(response => this.totalRecords = response.data.Total);
      },
    },
    async mounted() {
      await this.searchGroups();
    },
  };
  </script>