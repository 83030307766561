<template>
  <div class="row" style="float: left; margin-left: 4px">
    <span style="margin: 0 2pt">
      <base-input label="Nome">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="filters.name">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Organização de Venda">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Organização de Venda"
          v-model="filters.salesOrganization">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Canal de Distribuição">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Canal de Distribuição"
          v-model="filters.distributionChannel">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Divisão">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Divisão" v-model="filters.division">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-add" @click="addNew">Novo</button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filters: {
        id: null,
        name: null,
        salesOrganization: null,
        distributionChannel: null,
        division: null
      }
    };
  },
  methods: {
    addNew() {
      this.$router.push({ name: "OrganizationCreate" });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        id: null,
        name: null,
        salesOrganization: null,
        distributionChannel: null,
        division: null
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
};
</script>
