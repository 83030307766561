<template>
    <section>
        <base-list :totalRecords="totalRecords" @changePage="search" ref="baseList" title="Regras de Pedido">
            <CustomFieldFilter ref="filter" @filter="search" />
            <CustomFieldTable :customFields="customFields" @remove="remove" />
        </base-list>
        <router-view></router-view>
    </section>
</template>
<script>
import swal from "sweetalert2";
import BaseList from "@/components/BaseList";
import { mapActions, mapState } from "vuex";
import CustomFieldTable from '@/pages/customfields/components/CustomFieldTable';
import CustomFieldFilter from "../components/CustomFieldFilter.vue";

export default {
    data() {
        return {
            totalRecords: 0
        }
    },
    components: {
        BaseList,
        CustomFieldTable,
        CustomFieldFilter
    },
    computed: {
        ...mapState("customfield", ["customFields"])
    },
    methods: {
        ...mapActions('customfield', ['getCustomFields', 'removeCustomField']),
        async search() {
            let pagination = this.$refs.baseList.value();
            let filter = this.$refs.filter.value();

            this.getCustomFields({ ...pagination, ...filter }).then(res => {
                this.totalRecords = res.data.Total
            });
        },
        remove(id) {
            swal({
                title: "Gostaria de excluir o registro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.delete(id)
                    await this.search();
                }
            });
        },
        async delete(id) {
            await this.removeCustomField(id)
                .then(() => {
                    this.$showSuccess("Registro removido com sucesso!")
                }).catch(error => { this.$showError(error) });
        }
    },
    async mounted() {
        await this.search();
    }
}
</script>
