<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <div class="head-content">
                <h4 slot="header" class="card-title">
                    <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                        <i class="material-icons">arrow_back</i> </base-button><span> Pedido Fullfillment - Detalhes
                    </span>
                </h4>
            </div>
            <fulfillment-form />
        </card>
    </transition>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import FulfillmentForm from "../components/FulfillmentForm.vue";
import { EventBus } from "@/eventBus";

export default {
    components: {
        FulfillmentForm
    },
    computed: {
        ...mapState("health", ["displayRestrictedData"])
    },
    methods: {
        ...mapActions("fulfillment", ["getFulfillmentById"]),

        async init(){
            let displayRestrictedData = this.displayRestrictedData;
            let id = this.$route.params.id;
            await this.getFulfillmentById({ id, displayRestrictedData });
        }
    },
    async mounted() {
        EventBus.$on("toggleRestrictedDataDisplay", async () => {
            await this.init();
        });

        await this.init();
    }
}
</script>