<template >
    <el-table :data="logs">
        <el-table-column label="Id" prop="Id" />
        <el-table-column label="Sku" prop="Sku" />
        <el-table-column label="Depósito" prop="WarehouseCode" min-width="50" />
        <el-table-column align="center" label="Saldo Atual" prop="Quantity" min-width="100" />
        <el-table-column align="center" label="Tipo">
            <template slot-scope="scope">
                {{ "Enviado p/ Ecommerce" }}
            </template>
        </el-table-column>
        <el-table-column label="Dt. Atualização" prop="UpdateDate" :formatter="dateFormatter" />
    </el-table>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex';

export default {
    props: {
        logs: {
            type: Array
        }
    },
    data() {
        return {
            isVulcabras : false
        }
    },
    computed: {
        ...mapState(['realm'])
    },
    methods: {
        dateFormatter(row, col, value, index) {
            return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
        }
    },
    mounted() {
        if(this.realm === "vulcabras")
            this.isVulcabras = true;
    },
}
</script>
<style></style>
