<template>
  <BaseList :title="title" ref="baseList" :totalRecords="totalRecords" @changePage="searchPaymentMethods">
    <PaymentMethodsFilter ref="filter" @filter="searchPaymentMethods" />
    <PaymentMethodsTable :paymentMethods="paymentMethodsToTable" @evtRefresh="searchPaymentMethods" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import PaymentMethodsTable from "@/pages/PaymentMethods/components/PaymentMethodsTable";
import PaymentMethodsFilter from "@/pages/PaymentMethods/components/PaymentMethodsFilter";
import { mapActions, mapState,mapGetters } from "vuex";
import constants from "@/util/constants";

export default {
  components: {
    BaseList,
    PaymentMethodsTable,
    PaymentMethodsFilter,
  },
  data() {
    return {
      title: "Formas de pagamento",
      totalRecords: 0
    };
  },
  computed: {
    ...mapState(['ecommerce']),
    ...mapGetters("paymentMethod", ["paymentMethodsToTable"])
  },
  methods: {
    ...mapActions("paymentMethod", ["getPaymentMethods", "getCreditCardCodes", "getPaymentMethodCodes", "getPaymentMethodTypes", "getERPPaymentMethods", "getPaymentConditions"]),
    async init() {
      if (this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform))
        return;

      await this.getCreditCardCodes();
      await this.getPaymentMethodCodes();
      await this.getPaymentMethodTypes();
      await this.getERPPaymentMethods();
      await this.getPaymentConditions();
    },
    async searchPaymentMethods() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getPaymentMethods({ ...paginationFilter, ...listFilter })
        .then((response) => this.totalRecords = response.data.Total);
    },
    GetErpPlatformTypeByKey(key) {
      return ["Sap S/4 Hana", "Sap XML"].includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
  },
  async mounted() {
    await this.searchPaymentMethods();
    await this.init();
  },
};
</script>
