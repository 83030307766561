<template>
  <div class="row" style="float: left; margin-left: 4px">
    <span style="margin: 0 2pt">
      <base-input label="Nome">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome do Produto" v-model="filters.name">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Referência no E-commerce">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Referência no E-commerce"
          v-model="filters.ecommerceReference">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Referência no ERP">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Referência no ERP"
          v-model="filters.erpReference">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Condição de pagamento">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Condição de pagamento"
          v-model="filters.paymentCondition">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 18pt 2pt">
      <button class="custom-btn-add" @click="addNew" v-if="getUserPermission('31_c')">Novo</button>
    </span>
    <base-input style="margin: 18pt 2pt">
      <el-dropdown>
        <el-button class="custom-btn-filter" id="v-step-21">
          Ações<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span @click="showModalImport">Importar</span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <a :href="templateImport" download>Template</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </base-input>
    <PaymentMethodImportModal :show="showModal" @evtClose="closeModalImport" />
  </div>
</template>
<script>
import PaymentMethodImportModal from './PaymentMethodImportModal';

export default {
  components: {
    PaymentMethodImportModal,
  },
  data() {
    return {
      filters: {
        id: null,
        name: null,
        ecommerceReference: null,
        erpReference: null,
        paymentCondition: null
      },
      files: [],
      templateImport: require('@/assets/template-xls/Template-Formas-Pagamento.xlsx'),
      showModal: false,
    };
  },
  methods: {
    addNew() {
      this.$router.push({ name: "PaymentMethodCreate" });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        id: null,
        name: null,
        ecommerceReference: null,
        erpReference: null,
        paymentCondition: null,
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    showModalImport() {
      this.showModal = true;
    },
    closeModalImport() {
      this.showModal = false;
    },
  }
};
</script>
