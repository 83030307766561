<template>
    <el-table :data="logOrders">
        <el-table-column label="Id" align="center" prop="id" />
        <el-table-column label="Pedido" align="center" prop="orderNumber" />
        <el-table-column label="Data" align="center" prop="dateTime" :formatter="dateFormatter" />
        <el-table-column label="Motivo" align="center" prop="message" min-width="200" />
    </el-table>
</template>
<script>
import moment from "moment";
export default {
    props: {
        logOrders: {
            type: Array,
            default: function () {
                return [{
                    id: "",
                    orderNumber: "",
                    message: "",
                    ecommerceName: ""
                }];
            },
        },
    },
    methods: {
        dateFormatter(row, col, value, index) {
            return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
        }
    }
};
</script>
<style scoped>
.my-bg-header {
    background-color: #27293d !important;
}
</style>
  