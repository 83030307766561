<template>
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <card class="stacked-form">
        <form>
          <div class="row">
            <deadlineAlert :isDeadlineReached="isDeadlineReached()" />
            <div class="col-12">
              <div class="row">
                <div class="col-3">
                  <base-input label="Nome" v-model="fulfillmentCurrent.Customer.Name"
                    :readonly="isReadonlyData()" />
                </div>
                <div class="col-2">
                  <base-input label="CPF/CNPJ" v-model="fulfillmentCurrent.Customer.TaxIdentification"
                    :readonly="isReadonlyData()" />
                </div>
                <div class="col-2">
                  <base-input label="Inscrição Estadual" v-model="fulfillmentCurrent.Customer.StateRegistry"
                    :readonly="isReadonlyData()" />
                </div>
                <div class="col-2">
                  <base-input label="Telefone" v-model="fulfillmentCurrent.Customer.PhoneNumber"
                    :readonly="isReadonlyData()" />
                </div>
                <div class="col-2">
                  <base-input label="Celular" v-model="fulfillmentCurrent.Customer.Mobile"
                    :readonly="isReadonlyData()" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import deadlineAlert from "@/components/AnonymizationDeadlineAlert.vue";

export default {
  components: {
    deadlineAlert
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          operation: "",
          orderNumber: "",
          importedDate: null,
          store: "",
          status: ""
        }
      }
    }
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
    ...mapState("health", ["displayRestrictedData"])
  },
  methods: {
    isReadonlyData() {
      return !this.displayRestrictedData || this.fulfillmentCurrent.Anonymized;
    },
    isDeadlineReached() {
      return this.displayRestrictedData && this.fulfillmentCurrent.Anonymized;
    }
  }
}
</script>
