<template>
    <div class="modal fade" id="logModal" tabindex="-1" role="dialog" aria-labelledby="logModalLabel" aria-hidden="true"
        data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content bg-color-default">
                <div class="modal-header">
                    <h4 slot="header" id="logModalLabel" class="modal-title card-title">
                        Skus cauda longa do Produto
                    </h4>
                </div>
                <div class="modal-body">
                    <div>
                        <BaseList ref="baseList" :totalRecords="totalRecords" @changePage="searchSkus">
                            <el-table v-loading="loading" :data="skusLongTail">
                                <el-table-column align="center" label="SKU" min-width="40" prop="SkuCode"/>
                            </el-table>
                        </BaseList>
                    </div>
                    <div align="center">
                        <base-button @click="$router.push({ name: 'ProductList' })" class="btn-custom" type="primary">
                            Voltar
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseList from "@/components/BaseList";
import { mapActions } from "vuex";

export default {
    components: {
        BaseList,
    },
    data() {
        return {
            skusLongTail: [],
            skuId: null,
            loading: false,
            show: false,
            totalRecords: 0
        }
    },
    methods: {
        ...mapActions("product", ["getSkusLongTail"]),
        searchSkus() {
            this.loading = true;
            let paginationFilter = this.$refs.baseList.value();
            this.getSkusLongTail({ ...paginationFilter, skuId: this.skuId }).then(res => {
                this.skusLongTail = res.data.Data;
                this.totalRecords = res.data.Total;
                this.loading = false;
            }).catch(() => this.loading = false);
        },
    },
    mounted() {
        this.skuId = this.$route.query.productId;
        this.getSkusLongTail({ pageNumber: 1, pageSize: 5, skuId: this.skuId }).then(res => {
            this.skusLongTail = res.data.Data;
            this.totalRecords = res.data.Total;
            this.loading = false;
        });
        jQuery("#logModal").modal("show");
    },
    destroyed() {
        jQuery('.modal-backdrop').removeClass('modal-backdrop');
    },
};
</script>