<template>
  <el-table :data="logs">
    <el-table-column label="Id" prop="Id" />
    <el-table-column label="Sku" prop="Sku" />
    <el-table-column :label="labelWarehouse" prop="WarehouseCode" min-width="50" />
    <el-table-column
      align="center"
      label="Saldo Anterior"
      prop="QuantityOld"
      min-width="100"
    />
    <el-table-column align="center" label="Saldo Atual" prop="Quantity" min-width="100" />
    <el-table-column align="center" v-if="!isVulcabras" label="Tipo">
      <template slot-scope="scope">
        {{ "Captado no SAP" }}
      </template>
    </el-table-column>
    <el-table-column
      label="Dt. Atualização"
      prop="UpdateDate"
      :formatter="dateFormatter"
    />
  </el-table>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import constants from "@/util/constants";

export default {
  props: {
    logs: {
      type: Array,
    },
  },
  data() {
    return {
      isVulcabras: false,
      labelWarehouse: ""
    };
  },
  computed: {
    ...mapState(['realm', 'ecommerce']),
  },
  methods: {
    dateFormatter(row, col, value, index) {
      return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
    },
    init() {
      if (this.realm === "vulcabras") this.isVulcabras = true;
      this.labelWarehouse = this.isSapBusinessOne(this.ecommerce.Erp.Platform) ? "Depósito" : "Depósito - Centro";
    },
    isSapBusinessOne(key) {
      return ["Sap Hana", "Sap SQL", "Sap XML"].includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style></style>
