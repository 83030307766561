<template>
  <transition name="fade" mode="out-in">
    <div class="col-lg-12 col-sm-12 specify-margin">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Histórico de Preços</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input label="De">
                <el-date-picker type="date" format="dd/MM/yyyy" prefix-icon="el-icon-search" placeholder="Data Inicial"
                  v-model="Filters.searchFrom"></el-date-picker>
              </base-input>
            </span>

            <span style="margin: 0 10pt">
              <base-input label="Até">
                <el-date-picker type="date" format="dd/MM/yyyy" prefix-icon="el-icon-search" placeholder="Data Final"
                  v-model="Filters.searchTo"></el-date-picker>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input label="SKU">
                <el-input type="search" prefix-icon="el-icon-search" placeholder="SKU" v-model="Filters.name"></el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input label="Enviado p/ Ecommerce">
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.SendedToEcommerce">
                  <el-option class="select-primary" v-for="item in LogTypesPrice" :key="item.Key" :label="item.Label"
                    :value="item.Key"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input label="Captado no SAP">
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.ReceivedBySAP">
                  <el-option class="select-primary" v-for="item in LogTypesPrice" :key="item.Key" :label="item.Label"
                    :value="item.Key"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 18pt 10pt">
              <button class="custom-btn-filter" @click="GetLogPrices">
                Filtrar
              </button>
            </span>
            <span style="margin: 18pt 10pt">
              <button class="custom-btn-filter" @click="clearFilter">
                Limpar filtro
              </button>
            </span>
            <span style="margin: 18pt 10pt">
              <button class="custom-btn-save" @click="exportExcel()">
                Exportar
              </button>
            </span>
            <span style="margin: 18pt 10pt">
              <button class="custom-btn-save" style="min-width: 150px;" @click="$router.push({ name: 'LogSyncPrice' })">
                Sincronizar Preço
              </button>
            </span>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="tableData.Items">
            <el-table-column align="center" label="Id" prop="Id" />
            <el-table-column align="center" label="Sku" prop="Sku" />
            <el-table-column align="center" label="Lista de Preço" prop="PriceListId" />
            <el-table-column align="center" label="Preço Antigo" prop="PriceOf" />
            <el-table-column align="center" label="Preço Atual" prop="PricePer" />
            <el-table-column align="center" v-if="!isVulcabras" label="Enviado p/ Ecommerce" prop="SendedToEcommerce">
              <template slot-scope="scope">
                {{ scope.row.SendedToEcommerce ? "SIM" : "NÃO" }}
              </template>
            </el-table-column>
            <el-table-column align="center" v-if="!isVulcabras" label="Captado no SAP" prop="ReceivedBySAP">
              <template slot-scope="scope">
                {{ scope.row.ReceivedBySAP ? "SIM" : "NÃO" }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="Dt. Atualização" prop="UpdateDate" />
          </el-table>
        </div>
        <div slot="footer" class="
                col-12
                d-flex
                justify-content-center justify-content-between
                flex-wrap
              ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" @input="GetLogPrices"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
      <UpdateLog :showOldValue="false" :labelNewValue="'SKU(s) CAPTADO(s)'" :dataLog="dataLog" />
      <router-view></router-view>
    </div>
  </transition>
</template>
<script>
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import moment from "moment";
import { Filters } from "../Seller/Data/filters";
import UpdateLog from "../../components/UpdateLog.vue";
import { mapState } from 'vuex';
import constants from '@/util/constants';

export default {
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    ...mapState(['realm']),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.Total;
    },
  },
  data() {
    return {
      dataLog: [],
      ecommerce: "",
      selectedEcomm: "",
      showSpinner: false,
      isVulcabras: false,
      Filters: {
        type: null,
        name: null,
        searchFrom: moment().add(-1, "days"),
        searchTo: moment(),
        ReceivedBySAP: null,
        SendedToEcommerce: null,
      },
      LogTypesPrice: constants.LogTypesPrice,
      tableColumns: [
        {
          prop: "Id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "Sku",
          label: "Sku",
          minWidth: 100,
        },
        {
          prop: "PriceListId",
          label: "Lista de Preço",
          minWidth: 100,
        },
        {
          prop: "PriceOf",
          label: "Preço Antigo",
          minWidth: 100,
        },
        {
          prop: "PricePer",
          label: "Preço Atual",
          minWidth: 100,
        },
        {
          prop: "UpdateDate",
          label: "Dt. Atualização",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      tableData: [],
      searchedData: [],
    };
  },
  async mounted() {
    if (this.realm === "vulcabras")
      this.isVulcabras = true;

    this.selectedEcomm = this.$store.state.ecommerce.Name;
    await this.GetLogPrices();
  },
  methods: {
    async GetLogPrices() {

      this.showSpinner = true;

      let queryString = "";
      var from = "";
      var to = "";

      if (this.Filters.name) queryString += "&Sku=" + this.Filters.name;
      if (this.Filters.ReceivedBySAP != null) queryString += "&ReceivedBySAP=" + this.Filters.ReceivedBySAP;
      if (this.Filters.SendedToEcommerce != null) queryString += "&SendedToEcommerce=" + this.Filters.SendedToEcommerce;

      if (this.Filters.searchFrom != "" && this.Filters.searchFrom != null) {
        from = moment(this.Filters.searchFrom).format("YYYY-MM-DD") + " 00:00:00";
      }
      if (this.Filters.searchTo != "" && this.Filters.searchTo != null) {
        to = moment(this.Filters.searchTo).format("YYYY-MM-DD") + " 23:59:59";
      }

      if (!this.$isNullOrEmpty(from)) queryString += "&dtStart=" + from;
      if (!this.$isNullOrEmpty(to)) queryString += "&dtEnd=" + to;
      if (this.pagination.currentPage) queryString += "&currentPage=" + this.pagination.currentPage;
      if (this.pagination.perPage) queryString += "&perPage=" + this.pagination.perPage;

      let response = await this.$bahngleis.get(
        "api/LogPrice/GetPaginated?ecommerceName=" + this.selectedEcomm + queryString
      );

      this.tableData = response.data;
      this.showSpinner = false;
      this.formatDate();
      await this.GetLogAction();
    },
    GetLogAction: async function () {
      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.selectedEcomm +
        "&entity=ManualCapturePrice"
      );

      this.dataLog = responseLog.data;
    },
    clearFilter() {
      this.Filters.searchFrom = null;
      this.Filters.searchTo = null;
      this.Filters.name = null;
      this.Filters.ReceivedBySAP = null,
      this.Filters.SendedToEcommerce = null,
      this.GetLogPrices();
    },
    formatDate() {
      this.tableData.Items.forEach((element) => {
        element.UpdateDate = moment.utc(element.UpdateDate).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });
    },
    exportExcel: async function () {
      try {
        this.showSpinner = true;

        let queryString = "";

        if (this.Filters.name) queryString += "&Sku=" + this.Filters.name;

        if (this.Filters.searchFrom != "" && this.Filters.searchFrom != null) {
          queryString += "&dtStart=" + moment(this.Filters.searchFrom).format("YYYY-MM-DD") + " 00:00:00";
        }
        if (this.Filters.searchTo != "" && this.Filters.searchTo != null) {
          queryString += "&dtEnd=" + moment(this.Filters.searchTo).format("YYYY-MM-DD") + " 23:59:59";
        }

        this.showSpinner = false;

        let response = await this.$bahngleis.get(`/api/LogPrice/ExportLog?ecommerceName=${this.selectedEcomm}${queryString}`,
          {
            responseType: "blob",
          }
        );
        this.downloadFile("LogPrice.xlsx", response.data);

      } catch (error) {
        swal({
          title: "Atenção",
          text: "Ocorreu um erro ao exportar planilha",
          type: "error",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false,
        });

        this.ShowWarningNotification(error);
        return;
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    async filterChange(command) {
      switch (command) {
        case 1:
          this.filters.ReceivedBySAP = true;
          this.filters.SendedToEcommerce = false;
          break;
        case 2:
          this.filters.SendedToEcommerce = true;
          this.filters.ReceivedBySAP = false;
          break;
        default:
          this.filters.SendedToEcommerce = null;
          this.filters.ReceivedBySAP = null;
          break;
      }
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
