<template>
    <div class="row">
        <card card-body-classes="table-full-width">
            <div class="col-12">
                <div class="d-flex justify-content-center justify-content-between flex-wrap">
                    <div></div>
                    <div>
                        <button class="custom-btn-save" @click="explore">Buscar</button>
                    </div>
                </div>
            </div>
            <h4 slot="header" class="card-title">Consulta externa</h4>
            <base-input class="col-6" label="Onde">
                <el-select v-model="externalSearch.toWhich" placeholder="Selecione">
                    <el-option v-for="item in toWhichOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </base-input>
            <base-input v-if="externalSearch.toWhich !== 'xsEngine'" class="col-6" label="Path"
                v-model="externalSearch.path" />
            <base-input v-if="externalSearch.toWhich !== 'xsEngine'" class="col-6" label="Filtro"
                v-model="externalSearch.filter" />
            <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Select"
                v-model="externalSearch.select" />
            <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Expand"
                v-model="externalSearch.expand" />
            <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="OrderBy"
                v-model="externalSearch.orderBy" />
            <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Top" v-model="externalSearch.top" />
            <base-input class="col-6" label="Resultado">
                <el-input v-model="resultSearchExternal" type="textarea" :autosize="{ minRows: 20, maxRows: 50 }" />
            </base-input>
        </card>
        <div class="col-12">
            <UpdateLog :dataLog="logs" />
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import UpdateLog from "../../../components/UpdateLog.vue";
import constants from '@/util/constants'

export default {
    components: {
        UpdateLog
    },
    computed: {
        ...mapState('externalSearch', ['logs'])
    },
    data() {
        return {
            externalSearch: {
                path: "",
                filter: "",
                expand: "",
                orderBy: "",
                select: "",
                toWhich: "",
                top: ""
            },
            toWhichOptions: constants.ToWhichOptions,
            resultSearchExternal: ""
        };
    },
    methods: {
        ...mapActions("externalSearch", ['search', 'getLogs']),
        async load() {
            await this.getLogs();
        },
        async explore() {
            if (!this.validate()) return;

            await this.search({ ...this.externalSearch }).then(response => {
                this.jsonData(response.data);
                this.$showSuccess("Consulta realizada com sucesso");
            }).catch(error => { this.$showError(error) });
        },
        jsonData(data) {
            try {
                this.resultSearchExternal = JSON.stringify(data, null, 2);
            } catch (error) {
                return {};
            }
        },
        validate() {
            if (this.$isNullOrEmpty(this.externalSearch.path) && this.externalSearch.toWhich !== 'xsEngine') {
                this.$showError("Path obrigatório.");
                return false;
            } else if (this.externalSearch.path.includes('?')) {
                this.$showError("Path não pode conter '?'");
                return false;
            } else if (!this.$isNullOrEmpty(this.externalSearch.filter) && this.externalSearch.filter.includes('?')) {
                this.$showError("Filter não pode conter '?'");
                return false;
            } else if (this.$isNullOrEmpty(this.externalSearch.toWhich)) {
                this.$showError("Onde obrigatório.");
                return false;
            } else
                return true;
        },
    },
    async mounted() {
        await this.load();
    },
}
</script>
<style scoped></style>