<template>
  <Modal :modalContentClasses="'card'" :showClose="false" :show="show" class="custom-modal">
    <h4 slot="header" class="card-title">Importar arquivo com relação de formas de pagamento</h4>
    <div class="row">
      <ul>
        <li v-for="(file) in files" :key="file.id">
          <span>{{ file.name }}</span> -
          <span>{{ file.size | formatSize }}</span>
        </li>
      </ul>
      <div class="d-flex justify-content-between align-items-center">
        <file-upload class="btn btn-primary btn-sm" extensions="xlsx" accept=".xlsx" :multiple="false"
          :size="1024 * 1024 * 10" v-model="files" @input-filter="inputFilter" @input-file="inputFile" ref="upload">
          <i class="fa fa-plus"></i>
          Selecionar Arquivo
        </file-upload>
        <button type="button" class="btn btn-success btn-sm" v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="$refs.upload.active = true">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Começar Importação
        </button>
        <button type="button" class="btn btn-danger btn-sm" v-else @click.prevent="$refs.upload.active = false">
          <i class="fa fa-stop" aria-hidden="true"></i>
          Parar importação
        </button>
      </div>
    </div>
    <div class="pt-5">
      <h5 v-if="importErrors.length > 0" style="color: orange"><b>Arquivo importado com êxito! Porem algumas linhas não foram importadas: </b></h5>
      <h5 style="color: orange" v-for="(error,index) in importErrors" :key="index">{{ error }}</h5>
    </div>
    <br>
    <div class="d-flex justify-content-between">
      <el-button @click="closeModal" size="small">Fechar</el-button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import FileUpload from 'vue-upload-component';
import constants from '@/util/constants';
import { mapActions } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Modal,
    FileUpload
  },
  data() {
    return {
      typeOptionsDocument: [],
      typeOptionsWarehouse: [],
      files: [],
      importErrors: [],
    }
  },
  methods: {
    ...mapActions('paymentMethod', ['importPaymentMethods']),
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: async function (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.addFile = true;
      }
      if (newFile && oldFile) {
        let formData = new FormData();
        formData.append("file", this.$refs.upload.files[0].file);

        if (this.addFile) {
          this.addFile = false;

          await this.importPaymentMethods(formData).then(response => {
            if (response.status == 200) {
                if(response.data.errors && response.data.errors.length > 0)
                  this.importErrors = response.data.errors

                this.$showSuccess("O arquivo foi importado corretamente");
                this.addFile = true;
                this.files = [];
                this.$refs.upload.files[0].file.success = true;
            }
          });
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    closeModal() {
      this.importErrors = [];
      this.$emit("evtClose");
    }
  },
  mounted() {
    this.dialogVisible = true;
  },
  destroyed() {
    this.dialogVisible = false;
    this.importErrors = [];
  }
}
</script>
<style>
.modal-footer button {
  margin: 10;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #FFF;
  background-color: #7ed3d8;
  border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
  color: #FFF;
  border-color: #00ADB7;
  background-color: #00ADB7;
}

.el-button--primary {
  color: #FFF;
  background-color: #4eacb1;
  border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
