<template>
    <div class="modal fade" id="documentModal" tabindex="-1" role="dialog" aria-labelledby="documentModalLabel"
        aria-hidden="true">
        <div class="custom-modal-dialog modal-dialog modal-x1" role="document">
            <div class="modal-content bg-color-default">
                <div class="modal-header">
                    <h4 id="documentModalLabel">
                        JSON Enviado ao ERP
                    </h4>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true" class="text-white">
                            <i class="tim-icons icon-simple-remove"></i>
                        </span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="documentType">Documento:</label>
                                <el-input v-model="documentTypeValue" placeholder="Informe o ID correspondente"
                                    disabled />
                            </div>
                            <div class="form-group">
                                <label for="jsonData">JSON: <el-tooltip content="Copiar JSON" :open-delay="300"
                                        placement="top"><i class="el-icon-copy-document" @click="copyJson"
                                            style="cursor: pointer;"></i></el-tooltip></label>
                                <base-input>
                                    <div class="json-container" v-html="jsonData"></div>
                                </base-input>
                            </div>
                            <base-button type="primary" small class="btn-next" @click="getBusinessPartner()">
                                Parceiro de Negócio
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal'
import Prism from 'prismjs';
import 'prismjs/components/prism-json.min.js';
import 'prismjs/themes/prism-solarizedlight.css';


export default {
    components: {
        Modal
    },
    data() {
        return {
            documentTypeValue: null,
            minRowsSize: 4,
            maxRowsSize: 10,
            textareaVisible: true
        }
    },
    computed: {
        ...mapState("orders", ["logInternal"]),
        ...mapState("health", ["displayRestrictedData"]),
        jsonData() {
            try {
                const parsedJSON = JSON.parse(this.logInternal.message);
                const formattedJson = JSON.stringify(parsedJSON, null, 2);
                return Prism.highlight(formattedJson, Prism.languages.json, 'json');
            } catch (error) {
                return {};
            }
        }
    },
    methods: {
        ...mapActions("orders", ["getLogInternal"]),
        adjustJsonAutoSize() {
            const screenHeight = window.screen.height;

            const baseHeight = 1440;
            const maxLines = 20;
            const minLines = 15;

            const lineProportion = screenHeight / baseHeight;
            const calculatedMinRows = Math.round(minLines * lineProportion);
            const calculatedMaxRows = Math.round(maxLines * lineProportion);

            this.minRowsSize = Math.max(calculatedMinRows, 5);
            this.maxRowsSize = Math.max(calculatedMaxRows, 10);

            this.textareaVisible = false;
            this.$nextTick(() => {
                this.textareaVisible = true;
            });
        },
        async loadLog() {
            await this.getLogInternal({
                EcommerceName: this.$store.state.ecommerce.Name,
                LogType: this.$route.params.type,
                OrderNumber: this.$route.params.number,
                DisplayRestrictedData: this.displayRestrictedData
            });
            this.documentTypeValue = this.getDocumentName(this.$route.params.type);
        },
        getBusinessPartner() {
            this.getLogInternal({
                EcommerceName: this.$store.state.ecommerce.Name,
                LogType: '1',
                OrderNumber: this.$route.params.number,
                DisplayRestrictedData: this.displayRestrictedData
            });
            this.documentTypeValue = this.getDocumentName('1');
        },
        closeModal() {
            $(".modal-backdrop").hide();
            this.$router.go(-1);
        },
        closeModalOnOutsideClick(event) {
            if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
                this.closeModal();
            }
        },
        getDocumentName(prop) {
            switch (prop) {
                case '1':
                    return 'Parceiro de Negócio';
                case '2':
                    return 'Pedido';
                case '5':
                    return 'Contas a Receber';
                case '6':
                    return 'Nota Fiscal de Sáida';
                case '7':
                    return 'Picking';
                case '8':
                    return 'Adiantamento';
                case '11':
                    return 'Nota Fiscal de Saída';
                case '12':
                    return 'Recebimento de Mercadoria';
                case '13':
                    return 'Nota Fiscal de Entrada';
            }
        },
        async copyJson() {
            try {
                const parsedJSON = JSON.parse(this.logInternal.message);
                const formattedJson = JSON.stringify(parsedJSON, null, 2);
                await navigator.clipboard.writeText(formattedJson);
                this.$message.success("JSON copiado!");
            } catch (err) {
                this.$message.error("Falha ao copiar JSON!");
            }
        },
    },
    async mounted() {
        this.adjustJsonAutoSize();
        window.addEventListener('resize', this.adjustJsonAutoSize);
        await this.loadLog();
        setTimeout(() => {
            document.addEventListener('click', this.closeModalOnOutsideClick);
            jQuery("#documentModal").modal("show");
        }, 500);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeModalOnOutsideClick);
        window.removeEventListener('resize', this.adjustJsonAutoSize);
    },
}
</script>
<style scoped>
@import "../Order.css";

.modal-footer button {
    margin: 10;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: #7ed3d8;
    border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
    color: #FFF;
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-button--primary {
    color: #FFF;
    background-color: #4eacb1;
    border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}

.custom-modal-dialog {
    margin-top: -5%;
    max-width: 40%;
}

.json-container {
    font-family: monospace;
    white-space: pre-wrap;
    color: #f8f8f2;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 600px;
}

svg.iconify.iconify--mdi {
    cursor: pointer;
}
</style>
