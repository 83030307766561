<template>
  <BaseList :title="title" ref="baseList" :totalRecords="totalRecords" @changePage="searchPriceLists">
    <PriceListsFilter ref="filter" @filter="searchPriceLists" />
    <PriceListsTable :priceLists="priceListsToTable" @evtRefresh="searchPriceLists" @reload="searchPriceLists" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import PriceListsTable from "@/pages/PriceList/components/PriceListTable";
import PriceListsFilter from "@/pages/PriceList/components/PriceListFilter";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    BaseList,
    PriceListsTable,
    PriceListsFilter,
  },
  data() {
    return {
      title: "Lista de Preços",
      totalRecords: 0
    };
  },
  computed: {
    ...mapGetters("priceList", ["priceListsToTable"]),
    ...mapState(['ecommerce']),
  },
  methods: {
    ...mapActions("priceList", ["getPriceListsByEcommerce", "getErpPriceListsByEcommerce"]),
    async searchPriceLists() {
      let pagination = this.$refs.baseList.value();
      let filter = this.$refs.filter.value();

      await this.getPriceListsByEcommerce({ currentPage: pagination.pageNumber, perPage: pagination.pageSize, ...filter })
        .then((response) => (this.totalRecords = response.data.Total));
    },
    async loadSapPriceLists() {
      await this.getErpPriceListsByEcommerce(this.ecommerce.Name);
    }
  },
  async mounted() {
    await this.searchPriceLists();
    await this.loadSapPriceLists();
  },
};
</script>
