<template>
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <card class="stacked-form">
        <form>
          <div class="row">
            <deadlineAlert :isDeadlineReached="isDeadlineReached()" />
            <div class="col-12">
              <div class="row" v-for="(payment, index) in fulfillmentCurrent.OrderPayments" :key="index">
                <div class="col-12 col-lg-5">
                  <base-input label="Forma de Pagamento" v-model="payment.PaymentMethod"></base-input>
                </div>
                <div class="col-12 col-lg-3">
                  <base-input label="Parcelas" v-model="payment.Installments"></base-input>
                </div>
                <div class="col-12 col-lg-4">
                  <base-input label="Cód. TID" v-model="payment.TransactionCode"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-9 col-lg-6">
                  <base-input label="Rua" v-model="addressBilling.Street" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-3 col-lg-2">
                  <base-input label="N°" v-model="addressBilling.Number" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-12 col-lg-4">
                  <base-input label="Complemento" v-model="addressBilling.Complement"
                    :readonly="isReadonlyData()"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <base-input label="Bairro" v-model="addressBilling.Neighborhood"
                    :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-9 col-lg-3">
                  <base-input label="Cidade" v-model="addressBilling.City" :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-3 col-lg-2">
                  <base-input label="UF" v-model="addressBilling.Region" maxlength="2"
                    :readonly="isReadonlyData()"></base-input>
                </div>
                <div class="col-12 col-lg-3">
                  <base-input label="CEP" v-model="addressBilling.ZipCode" maxlength="8"
                    :readonly="isReadonlyData()"></base-input>
                </div>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import constants from "@/util/constants";
import deadlineAlert from "@/components/AnonymizationDeadlineAlert.vue";

export default {
  components: {
    deadlineAlert
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
    ...mapState("health", ["displayRestrictedData"]),
    addressBilling() {
      if (this.fulfillmentCurrent && this.fulfillmentCurrent.OrderAddress)
        return this.fulfillmentCurrent.OrderAddress.find(
          (a) => a.AddressType === constants.AddressType.Billing
        );

      return {
        Name: "",
        ZipCode: null,
        Street: null,
        Number: null,
        Complement: null,
        Neighborhood: null,
        Country: null,
        City: null,
        Region: null,
      };
    },
  },
  methods: {
    isReadonlyData() {
      return !this.displayRestrictedData || this.fulfillmentCurrent.Anonymized;
    },
    isDeadlineReached() {
      return this.displayRestrictedData && this.fulfillmentCurrent.Anonymized;
    }
  }
};
</script>
<style></style>