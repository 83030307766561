<template>
  <el-dialog title="Cancelar" :visible.sync="showModalCancel" width="30%" :before-close="handleClose">
    <div>
      <small style="color: gray">* Um pedido por linha</small>
      <div class="d-flex">
        <el-input placeholder="Pedido(s)" rows="5" type="textarea" v-model="number"></el-input>
      </div>
      <div>
        <label>Motivo:</label>
        <el-input placeholder="Motivo" v-model="reason"></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Cancelar</el-button>
      <el-button type="primary" @click="save">Confirmar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Modal from '@/components/Modal';
import { mapActions, mapState } from 'vuex';

export default {
  components: { Modal },
  props: {
    showModalCancel: { type: Boolean, default: false }
  },
  data() {
    return {
      number: null,
      orderNumbers: [],
      reason: null
    }
  },
  computed: {
    ...mapState(["ecommerce"]),
  },
  methods: {
    ...mapActions("orders", ["cancelSelected"]),
    handleClose() {
      this.clearNumber();
      this.$emit("close");
    },
    clearNumber() {
      this.number = null;
    },
    async save() {
      if (this.$isNullOrEmpty(this.number)) {
        this.$showError("Nenhum pedido especificado!");
        return;
      }

      this.orderNumbers = this.number.split("\n");

      await this.cancelSelected([
        { ecommerceName: this.ecommerce.Name, orders: this.orderNumbers, reason: this.reason }
      ])
        .then(async () => {
          this.$showSuccess("Pedido(s) cancelado(s) com sucesso")
        })
        .catch(() => this.$showError("Ocorreu um erro ao cancelado os pedidos"));

      this.handleClose();
    }
  }
}
</script>
