<template>
  <transition name="fade" mode="out-in">
    <div>
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
            <i class="material-icons">arrow_back</i>
          </base-button>
          Logs de Integração
        </h4>
        <div class="row" style="float: right">
          <div class="col-md-2">
            <base-input>
              <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Data inicial"
                v-model="Filters.searchFrom"></el-date-picker>
            </base-input>
          </div>

          <div class="col-md-2">
            <base-input>
              <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Data final"
                v-model="Filters.searchTo"></el-date-picker>
            </base-input>
          </div>

          <div class="col-md-2">
            <base-input>
              <el-input type="search" clearable prefix-icon="el-icon-search" placeholder="N° do pedido"
                v-model="orderNumber"></el-input>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="status" placeholder="Status">
          <el-option class="select-primary" v-for=" item  in  typeOfIntegrationLogs " :key="item.Key" :label="item.Label"
            :value="item.Key"></el-option>
        </el-select>
      </base-input>
          </div>
          <div class="col-md-1.5">
            <button class="custom-btn-filter" @click="getMessage(orderNumber, status)">
              Filtrar
            </button>
          </div>
          <div style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="clearFilter">
              Limpar Filtro
            </button>
          </div>
          <div class="col-md-1.5 ml-2">
            <button class="custom-btn-save" @click="exportExcel(orderNumber, status)">
              Exportar
            </button>
          </div>
        </div>
        <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
        </svg>
        <el-table :data="queriedData" style="width: 100%">
          <el-table-column label="Central de Ajuda" min-width="135" align="center">
            <div slot-scope="props">
              <el-link v-if="computedUrlHelpCenter(props.row.message)"
                :href="computedUrlHelpCenter(props.row.message)" target="_blank" type="warning">
                Ajuda ?
              </el-link>
              <a v-else href="#" class="nav-item dropdown-item disabled">
                -
              </a>
            </div>
          </el-table-column>
          <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
            :prop="column.prop" :label="column.label" :align="column.align ? column.align : 'center'">
            <div v-if="column.prop == 'status'" slot-scope="props">
              <div v-bind:class="getStatusCSSClass(props.row.logType.name, props.row.status)">
                {{ props.row.status | nameStatus }}
              </div>
            </div>
            <div v-else-if="column.prop == 'logType'">
              {{ props.row.logType.name }}
            </div>
            <div v-else-if="column.prop == 'dateTime'">
              {{ props.row.dateTime | toDateTime }}
            </div>
            <div v-else>
              {{ props.row[column.prop] }}
            </div>
          </el-table-column>
          <el-table-column label="Mensagem" align="center" min-width="450">
            <div slot-scope="props">
              <span v-html="props.row.message"></span>
            </div>
          </el-table-column>
        </el-table>
        <div slot="footer" class="
                  col-12
                  d-flex
                  justify-content-center justify-content-between
                  flex-wrap
                ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" @input="getMessage(orderNumber, status)"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="(item, index) in pagination.perPageOptions" :key="index"
                :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
  </transition>
</template>
<script>
import { BasePagination } from "src/components";
import moment from "moment";
import(/* webpackChunkName: "filter" */ 'src/util/filters');
import constants from '@/util/constants';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    BasePagination,
  },
  computed: {
    ...mapState('orders', ['helpCenter']),
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    computedUrlHelpCenter() {
      return (message) => {
        if(this.helpCenter){
          let urlHelpCenter = this.helpCenter.find(mapeamento => message.includes(mapeamento.Code));
          return urlHelpCenter ? urlHelpCenter.Url : null;
        }
      }
    }
  },
  data() {
    return {
      orderNumber: this.$route.query.orderNumber || "",
      selectedEcomm: "",
      showSpinner: false,
      status: this.$route.query.status || "",
      Filters: {
        searchFrom: null,
        searchTo: null,
      },
      tableColumns: [
        {
          prop: "orderNumber",
          label: "Pedido",
          minWidth: 180,
        },
        {
          prop: "logType",
          label: "Tipo de Evento",
          minWidth: 150,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 200,
        },
        {
          prop: "dateTime",
          label: "Data",
          minWidth: 180,
        }
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      tableData: [],
      searchedData: [],
      response: null,
      typeOfIntegrationLogs: constants.TypeOfIntegrationLogs,
    };
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    let orderNumber = this.$route.query.orderNumber;
    await this.getMessage(orderNumber || this.orderNumber, this.status);
    await this.getHelpCenter();
  },
  methods: {
    ...mapActions("orders", ["getHelpCenter"]),
    //---- On load ---------------------------------------------------------
    async exportExcel(orderNumber, status) {
      try {
        let queryString = `?Ecommerce=${this.selectedEcomm}`;

        if (orderNumber) queryString += `&orderNumber=${orderNumber}`;
        if (status) queryString += `&status=${status}`;

        if (this.Filters.searchFrom)
          queryString += `&MinDateTime=${moment(this.Filters.searchFrom).format(
            "YYYY-MM-DD"
          )} 00:00:00`;

        if (this.Filters.searchTo)
          queryString += `&MaxDateTime=${moment(this.Filters.searchTo).format(
            "YYYY-MM-DD"
          )} 23:59:59`;

        let response = await this.$bahngleis
          .get(`/logs/export${queryString}`,
            {
              responseType: "blob"
            }
          );
        this.downloadFile("LogIntegration.xlsx", response.data);

      } catch (error) {
        swal({
          title: "Atenção",
          text: "Ocorreu um erro ao exportar planilha",
          type: "error",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false,
        });
        this.ShowWarningNotification(error);
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    getMessage: async function (orderNumber, status) {
      // this.showSpinner = true;
        this.selectedEcomm = this.$store.state.ecommerce.Name;

      let queryString = `?EcommerceName=${this.selectedEcomm}`;

      if (orderNumber) queryString += `&orderNumber=${orderNumber}`;
      if (status) queryString += `&status=${status}`;

      if (this.Filters.searchFrom)
        queryString += `&MinDateTime=${moment(this.Filters.searchFrom).format(
          "YYYY-MM-DD"
        )} 00:00:00`;

      if (this.Filters.searchTo)
        queryString += `&MaxDateTime=${moment(this.Filters.searchTo).format(
          "YYYY-MM-DD"
        )} 23:59:59`;

      let page = this.pagination.currentPage || 1;
      let perPage = this.pagination.perPage || 5;

      queryString += `&InternalLog=false&PageNumber=${page}&PageSize=${perPage}`;

      let vm = this;
      await vm.$bahngleisWithoutLoading
        .get(`/logs${queryString}`)
        .then(function (response) {
          vm.tableData = response.data.Items;
          vm.pagination.total = response.data.Total;
        });
    },
    async clearFilter() {
      this.orderNumber = "";
      this.status = "";
      this.Filters.searchFrom = "";
      this.Filters.searchTo = "";

      await this.getMessage(this.orderNumber, this.status);

      let orderNumber = this.$route.query.orderNumber;
      if (orderNumber)
        this.$router.push({ name: 'Message' });
    },
    //TODO: AJUSTAR ISSO, TEM COMO MELHORAR
    getStatusCSSClass: function (eventType, status) {
      if (eventType === "Customizado") {
        return "status statusCustom";
      }

      const statusParams = {
        Error: "statusError",
        Complete: "statusComplete",
        Integrated: "statusIntegrated",
        Canceled: "statusCanceled",
        Started: "statusIntegrated",
        Information: "statusProcessing",
      };

      return statusParams[status]
        ? `status ${statusParams[status]}`
        : "status statusDefault";
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
