<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <slot name="header">
        <div class="d-flex col-6">
          <h4 v-if="title" class="card-title">{{ title }}</h4>
          <el-tooltip v-if="info" :content="info" effect="light" :open-delay="100" placement="top">
            <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
          </el-tooltip>
        </div>
      </slot>
      <div>
        <slot></slot>
      </div>
      <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
        <div class="col-5"></div>
        <div class="col-2">
          <label>
            Exibindo
            <span class="primary-text">{{ from + 1 }}</span> -
            <span class="primary-text">{{ to }}</span> de
            <span class="primary-text">{{ totalRecords }}</span> registros
          </label>
        </div>
        <div class="col-3">
          <base-pagination class="pagination-no-border" v-model="filter.pageNumber" :per-page="filter.pageSize"
            :total="totalRecords" @input="changePage()"></base-pagination>
        </div>
        <div class="col-2">
          <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
            v-model="filter.pageSize" placeholder="Per page">
            <el-option class="select-primary" v-for="item in pageSizeOptions" :key="item" :label="item"
              :value="item"></el-option>
          </el-select>
        </div>
      </div>
    </card>
  </transition>
</template>
<script>
import BasePagination from "./BasePagination";

export default {
  components: {
    BasePagination,
  },
  props: {
    totalRecords: { type: Number, default: 0 },
    title: { type: String, default: null },
    info: { type: String, default: null },
    pagination: {
      type: Object,
      default: function () {
        return {
          pageSize: 10,
          pageNumber: 1,
          pageSizeOptions: [10, 25, 50],
        };
      },
    }
  },
  data() {
    return {
      filter: {
        pageSize: this.pagination.pageSize,
        pageNumber: this.pagination.pageNumber
      },
      pageSizeOptions: this.pagination.pageSizeOptions,
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.filter.pageSize;
      if (this.totalRecords < highBound) {
        highBound = this.totalRecords;
      }
      return highBound;
    },
    from() {
      return this.filter.pageSize * (this.filter.pageNumber - 1);
    },
  },
  methods: {
    changePage() {
      this.$emit("changePage");
    },
    value() {
      return this.filter;
    },
    defaultPagination(){
      this.filter.pageNumber = 1;
    }
  },
};
</script>
