<template>
  <BaseList
    :title="title"
    ref="baseList"
    :totalRecords="filesLog.Total"
    @changePage="searchFiles"
  >
    <ImportListingFilter ref="filter" @filter="searchFiles" />
    <ImportListingTable :files="filesLog.Records"/>
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import ImportListingFilter from "../components/list/ImportListingFilter.vue";
import ImportListingTable from "../components/list/ImportListingTable.vue";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BaseList,
    ImportListingFilter,
    ImportListingTable,
  },
  data() {
    return {
      title : 'Importação de arquivos'
    };
  },
  computed : {
    ...mapState('product',['filesLog'])
  },
  methods: {
    ...mapActions('product',['getFilesLog']),
    async searchFiles() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getFilesLog({ ...paginationFilter, ...listFilter });
    },
  },
  async mounted() {
    await this.searchFiles();
  },
};
</script>  