<template>
  <el-table :data="attributes">
    <el-table-column label="Id" min-width="20" prop="id">
    </el-table-column>
    <el-table-column label="Nome" min-width="100" prop="name">
    </el-table-column>
    <el-table-column label="Descrição" align="center" prop="description">
    </el-table-column>
    <el-table-column label="Ativo" align="center" prop="active">
    </el-table-column>
    <el-table-column label="Tipo" align="center" prop="specificationType">
    </el-table-column>
    <el-table-column label="Valor" align="center" prop="valueType">
    </el-table-column>
    <el-table-column label="Grupo" align="center" prop="group">
    </el-table-column>
    <el-table-column :min-width="40" align="center" label="Ações">
      <div slot-scope="props">
        <base-button class="like !important btn-link" type="primary" v-if="!props.row.default" size="sm" icon @click="edit(props.row)">
          <i class="material-icons">mode_edit_outline</i>
        </base-button>
        <base-button class="edit btn-link" type="danger" v-if="!props.row.default" size="sm" icon @click="remove(props.row)">
          <i class="material-icons">delete_outline</i>
        </base-button>
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import { mapActions } from 'vuex';

export default {
  components: {
    ListBullet,
  },
  props: {
    attributes: {
      type: Array,
      default: function () {
        return [{
          id: "",
          name: "",
          description: "",
          active : "",
          specificationType: "",
          valueType: ""
        }];
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions("product",["removeAttribute"]),
    remove(attr) {
        this.removeAttribute(attr.id).then(res => {
            this.$showSuccess("Atributo removido com sucesso");
            this.$emit("reload");
        })
        .catch(error => this.$showError(error.response.data))
    },
    edit(attr){
      this.$router.push({ name : 'AttributeDetail', params : { id : attr.id}});
    }
  } 
};
</script>
<style scoped>
.my-bg-header {
  background-color: #27293d !important;
}
</style>
