<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Mapeamento</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input :type="searchInputType" style="padding-left:8px"
                  :placeholder="'Filtrar por ' + [[placeholderFilter]]" v-model="Filters.name">
                  <i slot="prefix" style="margin-right:10px" class="el-input__icon el-icon-search"></i>
                </el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page"
                  @change="changeFilter(Filters.type)">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="FilterProductSyncs()">
                Filtrar
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="GetProductSyncs()">
                Limpar filtro
              </button>
            </span>
            <span style="margin: 0 10pt" v-if="getUserPermission('22_c')">
              <button class="custom-btn-add" @click="AddNew()">Novo</button>
            </span>
            <span style="margin: 0 10pt">
              <button @click="importProductEcommerce()" class="custom-btn-filter-new"
                v-if="['Shopify', 'AnyMarket'].includes(selectedPlatform)">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Exportar Produtos Ecommerce
              </button>
            </span>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="queriedData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('22_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('22_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="
                            col-12
                            d-flex
                            justify-content-center justify-content-between
                            flex-wrap
                          ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
      <router-view></router-view>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i>
          </base-button>Cadastro do De / Para
        </h4>
      </div>
      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Sku</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informar o SKU" v-model="ProductSync.SKU" maxlength="150"
                v-bind:disabled="toDisabled === '1'"></el-input>
            </base-input>
          </div>

          <h4 class="card-title">Nome</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informar o Nome do Produto" v-model="ProductSync.Name" maxlength="150"
                v-debounce="GetDescriptionSku"></el-input>
            </base-input>
          </div>

          <h4 class="card-title">Sku E-Commerce</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input type="number" min="0" placeholder="Informar o SKU Id do E-commerce"
                v-model="ProductSync.SKUIdEcommerce"></el-input>
            </base-input>
          </div>
          <h4 class="card-title">Id E-Commerce</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input type="number" min="0" placeholder="Informar o Id do Produto do E-commerce"
                v-model="ProductSync.ProductIdEcommerce"></el-input>
            </base-input>
          </div>
          <div v-if="checkFunctionality">
            <h4 class="card-title">Status</h4>
            <div class="col-12 table-full-width table-responsive">
              <base-input>
                <el-select label="Status" placeholder="Status" v-model="ProductSync.Status" v-validate="'required'">
                  <el-option class="select-primary" v-for="(item, i) in listStatus" :key="i" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>

      <div class="col-12">
        <div class="
                      d-flex
                      justify-content-center justify-content-between
                      flex-wrap
                    ">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import planMixin from "src/mixins/planMixin.js";
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import { Filters } from "../../Seller/Data/filters";
import UpdateLog from "../../../components/UpdateLog.vue"
import ExportProductModal from "../components/ExportProductModal.vue";
import { mapState } from 'vuex';
import constants from '@/util/constants';

export default {
  mixins: [planMixin],
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog,
    ExportProductModal
  },
  computed: {
    ...mapState(['ecommerce']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      selectedEcomm: "",
      selectedPlatform: "",
      toDisabled: "0",
      searchInputType: "search",
      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "Sku", value: "SKU" },
          { name: "Sku E-Commerce", value: "SKUIdEcommerce" },
          { name: "Id E-Commerce", value: "ProductIdEcommerce" },
        ],
      },
      tableColumns: [
        {
          prop: "Id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "SKU",
          label: "Sku",
          minWidth: 100,
        },
        {
          prop: "Name",
          label: "Nome",
          minWidth: 100,
        },
        {
          prop: "SKUIdEcommerce",
          label: "Sku E-Commerce",
          minWidth: 100,
        },
        {
          prop: "ProductIdEcommerce",
          label: "Id E-Commerce",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],
      searchedData: [],

      showSpinner: false,
      response: null,

      ProductSync: null,

      checkFunctionality: true,

      erpTable: [],
      paymentConditionTable: [],

      toInsert: true,
      showSpinner1: false,
      showSpinner2: false,
      listStatus: [
        { name: "Nenhum", value: 0 },
        { name: "Processando", value: 1 },
        { name: "Integrado", value: 2 },
        { name: "Erro ecommerce", value: 3 },
        { name: "Produto criado", value: 4 },
        { name: "SKU criado", value: 5 },
      ],
      placeholderFilter: "",
    };
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.selectedPlatform = this.getPlatformNames(this.ecommerce.Platform);
    this.GetProductSyncs();
  },
  methods: {
    importProductEcommerce() {
      this.$router.push({
        name: 'ExportProductModal'
      });
    },
    getPlatformNames(key) {
      return constants.EcommercePlatForm.find((a) => a.Value === key).Label;
    },
    changeFilter(typeValue) {
      if (typeValue == "ProductIdEcommerce" || typeValue == "SKUIdEcommerce") {
        this.searchInputType = "number"
      } else {
        this.searchInputType = "search"
      }

      this.Filters.typeOptions.forEach((element) => {
        if (typeValue == null) {
          this.placeholderFilter = "";
        } else if (typeValue == element.value) {
          this.placeholderFilter = element.name;
        }
      });
    },

    GetProductSyncs: async function () {
      this.showSpinner = true;
      let response = await this.$bahngleis.get(
        "ProductSync?ecommerceName=" + this.selectedEcomm
      );

      this.tableData = response.data;
      this.showSpinner = false;
      this.Filters.name = null;
      this.Filters.type = null;
    },

    FilterProductSyncs: async function () {
      if (this.Filters.name == null || this.Filters.type == null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;
        this.response = await this.$bahngleis.get(
          "ProductSync?ecommerceName=" +
          this.selectedEcomm +
          "&" +
          this.Filters.type +
          "=" +
          this.Filters.name
        );

        this.tableData = await this.response.data;
        this.showSpinner = false;
      }
    },

    GetDescriptionSku: async function () {
      if (this.ProductSync.SKU != null && this.ProductSync.Name == null) {
        this.response = await this.$bahngleis.get(
          "ProductSync/GetDescriptionSku/" + this.ProductSync.SKU, {
            params: {
              ecommerceName: this.selectedEcomm,
            },
          }
        );
        if (this.response.status === 200) {
          this.ProductSync.Name = this.response;
        } else {
          this.ProductSync.Name = null;
        }
      }
    },
    ShowDetails: async function (productSync) {
      this.ProductSync = productSync;
      this.toInsert = false;
      this.toDisabled = "1";
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        productSync.Id +
        "&entity=ProductSync"
      );

      this.dataLog = await responseLog.data;
    },

    AddNew: function () {
      this.ProductSync = {
        Id: 0,
        Name: null,
        SKU: null,
        SKUIdEcommerce: null,
        ProductIdEcommerce: null,
        ecommerceName: null,
      };
      this.dataLog = [];
      this.toInsert = true;
      this.toDisabled = "0";
      this.showDetails = !this.showDetails;
    },

    Delete: function (productSync) {
      var vm = this;
      this.ProductSync = productSync;
      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          vm.Del();
          setTimeout(function () {
            vm.GetProductSyncs();
          }, 1000);
        }
      });
    },

    HideDetails: function () {
      this.ProductSync = null;

      this.showDetails = !this.showDetails;
    },

    Save: function () {
      if (this.$isNullOrEmpty(this.ProductSync.SKU)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>Sku</b> do Produto"
        );
      } else if (this.$isNullOrEmpty(this.ProductSync.SKUIdEcommerce)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>ID da Sku</b> no E-commerce"
        );
      } else if (this.$isNullOrEmpty(this.ProductSync.Name)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>Nome</b> no E-commerce"
        );
      } else if (this.$isNullOrEmpty(this.ProductSync.ProductIdEcommerce)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>ID do Produto</b> no E-commerce"
        );
      } else {
        var vm = this;
        if (vm.toInsert) {
          vm.Insert();
        } else {
          vm.Update();
        }
      }
    },

    Insert: function () {
      this.ProductSync.ecommerceName = this.selectedEcomm;
      var vm = this;
      this.$bahngleis
        .post("ProductSync", this.ProductSync, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$showSuccess("O cadastro foi inserido com sucesso");
          vm.showDetails = false;
          vm.GetProductSyncs();
        })
        .catch(error => {
          this.$showError(error.response.data)
        });
    },

    Del: function () {
      this.ProductSync.ecommerceName = this.selectedEcomm;
      let vm = this;
      this.$bahngleis
        .delete(
          "ProductSync/" +
          this.ProductSync.ecommerceName +
          "/Delete/" +
          this.ProductSync.SKU
        )
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O cadastro foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            vm.showDetails = false;
            vm.GetProductSyncs();
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o cadastro",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },

    Update: function () {
      var vm = this;
      this.$bahngleis
        .put("ProductSync", this.ProductSync, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
          vm.GetProductSyncs();
        })
        .catch(function () {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao atualizar o cadastro",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },

    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
