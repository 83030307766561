<template>
  <base-list :totalRecords="totalRecords" @changePage="search" ref="baseList">
    <div slot="header">
      <h4 style="display: flex; align-items: center; margin-bottom: 0px"  class="card-title">
          Log de ações do usuário 
          <el-tooltip content="Log dos últimos 6 meses de ações dos usuários." effect="light" :open-delay="100" placement="top">
                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
          </el-tooltip>
          <input style="margin-left: 3%" type="checkbox" id="checkbox" v-model="allEcommerce" @change="search"  />
          <label style="color: #00f1e6;
                  font-size: 12.6px;
                  margin: 0px 15px 0px 10px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;" for="checkbox">
              Todos os e-commerces
          </label>
      </h4>
    </div>
    <LogActionFilter ref="filter" @filter="search" @export="exportLogActions" />
    <LogActionTable :logActions="logActions" />
  </base-list>
</template>
<script>
import BaseList from "@/components/BaseList"
import { mapActions, mapState } from "vuex";
import LogActionFilter from "../components/LogActionFilter.vue"
import LogActionTable from "../components/LogActionTable.vue"

export default {
  data() {
    return {
      totalRecords: 0,
      allEcommerce : false
    }
  },
  components: {
    BaseList,
    LogActionFilter,
    LogActionTable
  },
  computed: {
    ...mapState("logOrder", ["logActions", "users"])
  },
  methods: {
    ...mapActions("logOrder", ["getLogActions", "getUsers", "exportLogAction"]),
    async init() {
      await this.getUsers();
      await this.search();
    },
    async search() {
      let pagination = this.$refs.baseList.value();
      let filter = this.$refs.filter.value();

      await this.getLogActions({ ...pagination, ...filter, allEcommerce: this.allEcommerce }).then(res => {
        this.totalRecords = res.data.Total
      });
    },
    async exportLogActions() {
      let filter = this.$refs.filter.value();

      await this.exportLogAction({...filter, allEcommerce: this.allEcommerce})
        .then(response => {
          this.downloadFile("log-de-ações-de-usuários.xlsx", response.data);
        });
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
  },
  async mounted() {
    await this.init();
  },
}
</script>
<style scoped></style>
