import { render, staticRenderFns } from "./LogOrder.vue?vue&type=template&id=58a4dddf&scoped=true&"
import script from "./LogOrder.vue?vue&type=script&lang=js&"
export * from "./LogOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a4dddf",
  null
  
)

export default component.exports