<template>
    <div class="row" style="float: left">
      <span style="margin: 0 10pt">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="filters.name">
          </el-input>
        </base-input>
      </span>
      <span style="margin: 0 10pt">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Descrição" v-model="filters.description">
          </el-input>
        </base-input>
      </span>
      <span style="margin: 0 10pt">
        <button class="custom-btn-filter" @click="filter">Filtrar</button>
      </span>
      <span style="margin: 0 10pt">
        <button class="custom-btn-filter" @click="clearFilter">
          Limpar filtro
        </button>
      </span>
      <span style="margin: 0 10pt">
        <button class="custom-btn-add" @click="addNew">Novo</button>
      </span>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        filters: {
          name: null,
          description: null,
        },
      };
    },
    methods: {
      addNew() {
        this.$router.push({ name: "AttributeCreate" });
      },
      filter() {
        this.$emit("filter");
      },
      clearFilter() {
        this.filters = {
          name: "",
          description: "",
        };
        this.$emit("filter");
      },
      value() {
        return this.filters;
      }
    },
  };
  </script>