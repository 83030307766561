<template>
  <base-list ref="baseList" title="Grupos" :totalRecords="totalRecords" @changePage="searchGroups">
    <div class="d-flex">
      <base-input class="m-1 col-4" placeholder="Nome do Grupo" v-model="name" />
      <base-button @click="addGroup" round icon type="primary" class="mt-1 ml-3">
        <i class="tim-icons icon-simple-add"></i>
      </base-button>
    </div>
    <el-table :data="groups" style="width: 100%">
      <el-table-column prop="Id" label="Id"></el-table-column>
      <el-table-column prop="Name" label="Nome"></el-table-column>
      <el-table-column :min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.Id)">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
    </el-table-column>
    </el-table>
  </base-list>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseList from '@/components/BaseList.vue';
import Modal from "@/components/Modal.vue";

export default {
  components: { BaseList, Modal },
  data() {
    return {
      dialogVisible: false,
      name: null,
      groups: [],
      totalRecords: 0
    };
  },
  computed: {
    ...mapState("product", ["productCurrent"]),
    ...mapState(["ecommerce"]),
  },
  methods: {
    ...mapActions("product", ["getGroups", "saveGroup","removeGroup"]),
    handleClose() {
      this.$router.go(-1);
    },
    addGroup() {
      if (!this.name) {
        this.$showError("Adicione o nome");
        return;
      }

      this.saveGroup({ Name: this.name , EcommerceName: this.ecommerce.Name})
        .then(() => {
          this.$showSuccess("Grupo adicionado com sucesso")
          this.searchGroups()
        })
        .catch((error) => this.$showError(error))
    },
    searchGroups() {
      let paginationFilter = this.$refs.baseList.value();
      this.getGroups({...paginationFilter, EcommerceName: this.ecommerce.Name})
        .then((res) => { 
          this.groups = res.data.Records;
          this.totalRecords = res.data.Total;
        })
        .catch((error) => this.$showError(error));
    },
    remove(id){
      this.removeGroup(id)
        .then(() => {
          this.$showSuccess("Removido com sucesso!");
          this.searchGroups();
        })
        .catch((error) => this.$showError(error));
    }
  },
  mounted() {
    this.dialogVisible = true;
    this.searchGroups();
  },
  destroyed() {
    this.dialogVisible = false;
  }
};
</script>
<style>
.modal-content {
  background-color: #27293d;
}
</style>