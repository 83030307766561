<template>
  <BaseList
    ref="baseList"
    :totalRecords="fileLogCurrent.Total"
    @changePage="searchLogs"
  >
  <template slot="header">
    <h4 class="card-title">
      <base-button
        class="like !important btn-link"
        type="primary"
        size="sm"
        icon
        @click="$router.go(-1)"
      >
        <i class="material-icons">arrow_back</i>
      </base-button>
      Detalhe do Arquivo
    </h4>
  </template>
    <el-table :data="fileLogCurrent.Records">
      <el-table-column label="Nº da Linha" align="center" min-width="80" prop="Line"></el-table-column>
      <el-table-column label="Nome da Tabela"  align="center" min-width="80" prop="Sheet"></el-table-column>
      <el-table-column label="Status" min-width="50" align="center" prop="status">
        <template slot-scope="scope">
          <div class="status statusError">Erro</div>
        </template>
      </el-table-column>
      <el-table-column label="Detalhes" align="center" min-width="100" prop="Message">
      </el-table-column>
    </el-table>
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BaseList,
  },
  data() {
    return {
      filters: {
        fileId : null
      },
      logs : []
    };
  },
  computed: {
    ...mapState('product',['fileLogCurrent'])
  },
  methods: {
    ...mapActions('product',['getFileLogDetail']),
    async searchLogs(){
      let paginationFilter = this.$refs.baseList.value();

      await this.getFileLogDetail({ ...paginationFilter, ...this.filters });
    }
  },
  async mounted() {
    let id = this.$route.params.id;
    this.filters.fileId = id;
    await this.searchLogs();
  },
};
</script>