<template>
  <div>
    <span v-if="isIcon">
      <el-tooltip v-if="isButtonVisible" content="Cancelar Pedido" effect="light" :open-delay="300" placement="top">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="confirmCancel">
          <i class="material-icons" style="color: rgb(165, 42, 42);">highlight_off</i>
        </base-button>
      </el-tooltip>
    </span>
    <span v-else>
      <button v-if="isButtonVisible" @click="confirmCancel" class="custom-btn-cancel mr-2">
        Cancelar Pedido
      </button>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import swal from "sweetalert2";
export default {
  name: 'CancelOrderButton',
  props: {
    order: {
      type: Object,
      required: true
    },
    isIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isButtonVisible: false,
      queryParams: {
        Number: null,
        EcommerceName: null,
        Reason: null,
        UserName: localStorage["profile_username"]
      }
    }
  },
  methods: {
    ...mapActions("orders", ["cancelOrder"]),

    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    confirmCancel() {
      swal({
        title: 'Tem certeza?',
        text: 'Cancelar um pedido é irreversível!',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        buttonsStyling: false,
        input: 'text',
        inputPlaceholder: 'Motivo do cancelamento',
        inputLabel: 'Informe o motivo do cancelamento:',
        inputValidator: (value) => {
          return !value && 'É necessário informar o motivo do cancelamento!'
        }
      }).then((result) => {
        if (result.value) {
          this.queryParams.Id = this.order.Id
          this.queryParams.Number = this.order.Number
          this.queryParams.EcommerceName = this.order.EcommerceName
          this.queryParams.Reason = result.value;
          this.cancelOrder(this.queryParams)
            .then(() => {
              this.$showSuccess("O pedido foi cancelado");
              if (this.$route.name === 'Orders') {
                this.$emit('reload-list');
              } else {
                this.$router.push({ name: 'Orders' });
              }
            })
            .catch(() => this.$showError("Ocorreu um erro ao cancelar o pedido"))
        }
      })
    }
  },
  watch: {
    order: {
      handler(newOrder) {
        if (this.getUserPermission('29_c') && (newOrder.Status.BahnReference === 'error' || newOrder.Status.BahnReference === 'holded')) {
          this.isButtonVisible = true;
        } else {
          this.isButtonVisible = false;
        }
      },
      immediate: true
    }
  },
}
</script>
