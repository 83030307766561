<template>
    <div class="row">
        <span style="margin: 0 10pt">
            <base-input>
                <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.dtStart"></el-date-picker>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.dtEnd"></el-date-picker>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Sku" v-model="filters.Sku">
                </el-input>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
          <base-input>
            <el-select class="select-primary mb-3 pagination-select" v-model="filters.type" placeholder="Informe o Tipo"
              @change="filterChange">
              <el-option class="select-primary" v-for="item in LogTypesStock" :key="item.Key" :label="item.Label"
                :value="item.Key"></el-option>
            </el-select>
          </base-input>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="filter">Filtrar</button>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="clearFilter">
                Limpar filtro
            </button>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-save" @click="exportExcel">
                Exportar
            </button>
        </span>
        <span style="margin: 0 10pt">
          <button class="custom-btn-save" style="min-width: 150px;" @click="$router.push({ name: 'LogSyncStock' })">
            Sincronizar Estoque
          </button>
        </span>
    </div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import constants from '@/util/constants';

export default {
  data() {
    return {
      filters: {
        dtStart: moment().add(-1, "days"),
        dtEnd: moment(),
        Sku: null,
        Selected: null,
        ReceivedBySAP: null,
        SendedToEcommerce: null,
      },
      LogTypesStock: constants.LogTypesStock
    }
  },
  methods: {
    ...mapActions('logOrder', ['exportLogStock']),
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        dtStart: null,
        dtEnd: null,
        Sku: null,
        ReceivedBySAP: null,
        SendedToEcommerce: null,
      };

      this.$emit("filter");
    },
    value() {
      if (this.filters.dtStart)
        this.filters.dtStart = moment(this.filters.dtStart)
          .format('YYYY-MM-DD HH:mm:ss');

      if (this.filters.dtEnd)
        this.filters.dtEnd = moment(this.filters.dtEnd)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DD HH:mm:ss');

      return this.filters;
    },
    async exportExcel() {
      await this.exportLogStock(this.value()).then(res => {
        this.downloadFile("LogStock.xlsx", res.data);
      }).catch(() => {
        swal({
          title: "Atenção",
          text: "Ocorreu um erro ao exportar planilha",
          type: "error",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false,
        });
      });
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    async filterChange(command) {
      switch (command) {
        case 1:
          this.filters.ReceivedBySAP = true;
          this.filters.SendedToEcommerce = false;
          break;
        case 2:
          this.filters.SendedToEcommerce = true;
          this.filters.ReceivedBySAP = false;
          break;
        default:
          this.filters.SendedToEcommerce = null;
          this.filters.ReceivedBySAP = null;
          break;
      }
    }
  },

}
</script>
<style lang="">

</style>
