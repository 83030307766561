<template>
  <div class="row" style="float: left">
    <span style="margin: 0 10pt">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Id" v-model="filters.ProductId">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.Status" placeholder="Status">
          <el-option class="select-primary" v-for="item in statuses" :key="item.name" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.Type" placeholder="Tipo">
          <el-option class="select-primary" v-for="item in types" :key="item.name" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-add" @click="exportProductIntegrationLog">Exportar</button>
    </span>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      filters: {
        ProductId: null,
        Status: null,
        Type: null,
      },
      statuses: [
        {
          name: "Sucesso",
          value: 0,
        },
        {
          name: "Erro",
          value: 1,
        },
        {
          name: "Iniciado",
          value: 2,
        },
      ],
      types: [
        {
          name: "Atualização",
          value: 1,
        },
        {
          name: "Novo Cadastro",
          value: 2,
        },
      ],
    };
  },
  methods: {
    ...mapActions("product",["exportProductIntegration"]),
    async exportProductIntegrationLog() {
      await this.exportProductIntegration(this.filters).then(res => {
        this.downloadFile("Produtos Importação.xlsx", res.data);
      })
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        name: "",
        id: "",
        status: null,
        type: null,
      };
    },
    value() {
      return this.filters;
    }
  },
};
</script>