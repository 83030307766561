<template>
  <BaseList
    :title="title"
    ref="baseList"
    :totalRecords="productIntegrationLog.length"
    @changePage="searchProducts"
  >
    <ProductListFilter ref="filter" @filter="searchProducts" />
    <ProductListTable :products="productIntegrationLog" @evtRefresh="searchProducts" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import ProductListTable from "@/pages/Product/components/list/ProductIntegrationTable";
import ProductListFilter from "@/pages/Product/components/list/ProductIntegrationFilter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseList,
    ProductListTable,
    ProductListFilter,
  },
  data() {
    return {
      title: "Detalhes da Integração",
    };
  },
  computed: {
    ...mapGetters("product", ["productIntegrationLog"])
  },
  methods: {
    ...mapActions("product", ["getProductIntegration"]),
    async searchProducts() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getProductIntegration({ ...paginationFilter, ...listFilter });
    },
  },
  async mounted() {
    await this.searchProducts();
  },
};
</script>