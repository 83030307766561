<template>
    <el-table :data="fulfillmentCurrent.Products">
    <el-table-column label="Sku" min-width="100"  prop="SKU"></el-table-column>
    <el-table-column label="Descrição" min-width="200" prop="Name">
    </el-table-column>
    <el-table-column align="center" label="Preço Unitário" prop="Price">
        <template slot-scope="scope">
        {{scope.row.Price | toCurrency}}
      </template>
    </el-table-column>
    <el-table-column align="center" label="Quantidade"  prop="Quantity">
    </el-table-column>
    <el-table-column align="center" label="Total">
        <template slot-scope="scope">
        {{ (scope.row.Price * scope.row.Quantity) | toCurrency}}
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState("fulfillment",["fulfillmentCurrent"]),
    },
}
</script>
