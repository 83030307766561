<template>
  <el-table :data="files">
    <el-table-column label="Nome do arquivo" min-width="80" prop="File"></el-table-column>
    <el-table-column label="Status" min-width="50" align="center" prop="StatusDescription">
      <template slot-scope="scope">
        <div :class="getClassStatus(scope.row.StatusDescription)">{{ scope.row.StatusDescription }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Data da Importação" min-width="100" prop="ProcessDate">
      <template slot-scope="scope">
        {{ scope.row.ProcessDate | toDateTime }}
      </template>
    </el-table-column>
    <el-table-column :min-width="40" align="center" label="Ações">
      <div slot-scope="props">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.Id)">
          <i class="material-icons">mode_edit_outline</i>
        </base-button>
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    files: {
      type: Array,
      default: function () {
        return [{
          id: "",
          file: "",
          status: "",
          statusDescription: "",
          processDate: null
        }];
      },
    },
  },
  methods: {
    getClassStatus(status) {
      switch (status) {
        case 'Sucesso':
          return 'status statusIntegrated';
        case 'Erro':
          return 'status statusError'
        default:
          return 'status statusScheduled';
      }
    },
    edit(id) {
      if (id)
        this.$router.push({ name: 'ProductDetailFileImport', params: { id: id } });
    },
  }
};
</script>
 
  