<template>
  <div>
    <el-table :data="organizations" ref="multipleTable">
      <el-table-column label="Id" min-width="50" align="center" prop="id"></el-table-column>
      <el-table-column label="Nome" min-width="100" prop="name">
      </el-table-column>
      <el-table-column label="Organização de Venda" min-width="100" prop="salesOrganization">
      </el-table-column>
      <el-table-column label="Canal de Distribuição" min-width="100" prop="distributionChannel">
      </el-table-column>
      <el-table-column label="Divisão" min-width="100" prop="division">
      </el-table-column>
      <el-table-column :min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.id)">
            <i class="material-icons">mode_edit_outline</i>
          </base-button>
          <base-button class="like !important btn-link" type="danger" size="sm" icon @click="remove(props.row.id)">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <router-view></router-view>
  </div>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import swal from "sweetalert2";
import { mapActions } from 'vuex';

export default {
  components: {
    ListBullet
  },
  props: {
    organizations: {
      type: Array,
      default: function () {
        return [{
          id: "",
          name: "",
          salesOrganization: "",
          distributionChannel: "",
          division: ""
        }];
      },
    },
  },
  methods: {
    ...mapActions('organization', ['getOrganizations', 'removeOrganization']),
    edit(id) {
      this.$router.push({ name: "OrganizationDetail", params: { id: id } });
    },
    remove(id) {
      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.delete(id)
        }
      });
    },
    async delete(id) {
      await this
        .removeOrganization(id)
        .then(async () => {
          this.$showSuccess("O cadastro foi excluido com sucesso");
          await this.getOrganizations();
        })
        .catch(error => { this.$showError(error) });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style scoped></style>
