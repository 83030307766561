<template>
  <div>
    <el-table :data="priceLists" ref="multipleTable">
      <el-table-column label="Id" min-width="30" align="center" prop="id"></el-table-column>
      <el-table-column label="Nome" min-width="150" prop="name"></el-table-column>
      <el-table-column label="Código" min-width="40" prop="priceListCode"></el-table-column>
      <el-table-column label="Ativo" min-width="40" align="center" prop="active">
        <template slot-scope="scope">
          <i v-if="scope.row.active" class="far fa-check-circle text-success"></i>
          <i v-else class="far fa-times-circle text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column label="Principal" min-width="40" align="center" prop="isMain">
        <template slot-scope="scope">
          <i v-if="scope.row.isMain" class="far fa-check-circle text-success"></i>
          <i v-else class="far fa-times-circle text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column label="B2B" min-width="40" align="center" prop="isB2B">
        <template slot-scope="scope">
          <i v-if="scope.row.isB2B" class="far fa-check-circle text-success"></i>
          <i v-else class="far fa-times-circle text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column label="Desconto" min-width="40" align="center" prop="isDiscount">
        <template slot-scope="scope">
          <i v-if="scope.row.isDiscount" class="far fa-check-circle text-success"></i>
          <i v-else class="far fa-times-circle text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button v-if="getUserPermission('34_r')" class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.id)">
            <i class="material-icons">mode_edit_outline</i>
          </base-button>
          <base-button v-if="getUserPermission('34_c')" class="like !important btn-link" type="danger" size="sm" icon @click="remove(props.row.id)">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <router-view></router-view>
  </div>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import swal from "sweetalert2";
import { mapActions } from 'vuex';

export default {
  components: {
    ListBullet
  },
  props: {
    priceLists: {
      type: Array,
      default: function () {
        return [{
          id: "",
          name: "",
          active: false,
          priceListCode: "",
          isMain: false,
          isB2B: false,
          isDiscount: false,
        }];
      },
    },
  },
  methods: {
    ...mapActions('priceList', ['getPriceListsByEcommerce', 'removePriceList']),
    edit(id) {
      this.$router.push({ name: "PriceListDetail", params: { id: id } });
    },
    remove(id) {
      swal({
        title: "Gostaria de desativar o registro?",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.delete(id)
        }
      });
    },
    async delete(id) {
      await this
        .removePriceList(id)
        .then(async () => {
          this.$showSuccess("O cadastro foi desativado com sucesso");
          this.$emit("reload");
        })
        .catch(error => { this.$showError(error) });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style scoped></style>
